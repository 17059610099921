import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  SecondaryButton,
  Modal,
  AvatarMedium,
} from '../../components';
import { SendMessageForm } from '../../forms';
import config from '../../config';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';
import RequestExtraPaymentModal from './RequestExtraPaymentModal/RequestExtraPaymentModal';
import HandleExtraPaymentModal from './HandleExtraPaymentModal/HandleExtraPaymentModal';
import RequestCompletePaymentModal from './RequestCompletePaymentModal/RequestCompletePaymentModal';
import HandleCompletePaymentModal from './HandleCompletePaymentModal/HandleCompletePaymentModal';
import DateAndTimePicker from './DateAndTimePicker';
import { types as sdkTypes } from '../../util/sdkLoader';
import { post } from '../../util/api';

const { UUID } = sdkTypes;

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

Date.prototype.today = function() {
  return (
    (this.getDate() < 10 ? '0' : '') +
    this.getDate() +
    '/' +
    (this.getMonth() + 1 < 10 ? '0' : '') +
    (this.getMonth() + 1) +
    '/' +
    this.getFullYear()
  );
};

// For the time now
Date.prototype.timeNow = function() {
  return (
    (this.getHours() < 10 ? '0' : '') +
    this.getHours() +
    ':' +
    (this.getMinutes() < 10 ? '0' : '') +
    this.getMinutes()
  );
};

import css from './TransactionPanel.module.css';
import MakeOfferModal from './MakeOfferModal/MakeOfferModal';
import { findConfigForSelectFilter } from '../../util/search';
import PreviewFile from '../PreviewFile/PreviewFile';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.setRescheduleModalOpen = this.setRescheduleModalOpen.bind(this);
    this.setSubmitDisabled = this.setSubmitDisabled.bind(this);
    this.setTimeNotAvailableError = this.setTimeNotAvailableError.bind(this);
  }

  setRescheduleModalOpen(value) {
    this.setState({ rescheduleModalOpen: value });
  }

  setSubmitDisabled(value) {
    this.setState({
      submitDisabled: value,
    });
  }

  setTimeNotAvailableError(value) {
    this.setState({
      timeNotAvailableError: value,
    });
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onRequestSecondPayment,
      onRequestCompletePayment,
      onSecondPayment,
      onCompletePayment,
      isCustomProductService,
      isConsutativeService,
      isExperimentalService,
      onSendOffer,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);

    const isMessageTransaction =
      currentTransaction?.attributes?.protectedData?.enquiryValues?.message;

    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const isCustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = isCustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = isCustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const selectedService = currentTransaction?.attributes?.protectedData?.focusedService;
    const serviceWithDeposit = selectedService?.depositPercentage;

    const lastTransition = currentTransaction?.attributes?.lastTransition;

    const txJustAccepted =
      lastTransition === 'transition/accept' ||
      lastTransition === 'transition/accept-without-booking';
    const transitions = currentTransaction?.attributes?.transitions;

    // extra payment / second payment
    const isSecondPaymentRequested = lastTransition === 'transition/request-second-payment';
    const isSecondPaymentCompleted = transitions.find(
      t => t.transition === 'transition/second-payment'
    );

    //complete payment - the leftover amount after deposit
    const isCompletePaymentRequested = lastTransition === 'transition/request-complete-payment';
    const isCompletePaymentCompleted = transitions.find(
      t => t.transition === 'transition/complete-payment'
    );

    const isAccepted = transitions.find(
      t =>
        t.transition === 'transition/accept' || t.transition === 'transition/accept-without-booking'
    );

    const isRescheduleAccepted =
      currentTransaction.attributes.lastTransition === 'transition/accept-reschedule-by-provider' ||
      currentTransaction.attributes.lastTransition === 'transition/accept-reschedule-by-customer';

    const isOfferMade = currentTransaction.attributes.protectedData.offerAmount;
    const showBookingPanelOnEnquiry = isExperimentalService
      ? isCustomer && !isProviderBanned && isOfferMade
      : isCustomer && !isProviderBanned;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: showBookingPanelOnEnquiry && hasCorrectNextTransition,
          showWaitingForOffer: isCustomer && isExperimentalService && !isOfferMade,
          showMakeOfferButton: isProvider && isExperimentalService && !isOfferMade,
          showOfferLabel: isOfferMade,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,

          showSecondPaymentSection: (isAccepted || isRescheduleAccepted) && isProvider,
          showRequestExtraPaymentButton:
            (isAccepted || isRescheduleAccepted) &&
            isProvider &&
            !isCompletePaymentRequested &&
            !isSecondPaymentRequested &&
            !isCustomProductService,
          isSecondPaymentRequested,
          isSecondPaymentCompleted,

          showCompletePaymentSection:
            (isAccepted || isRescheduleAccepted) && isProvider && serviceWithDeposit,
          showRequestCompletePaymentButton:
            (txJustAccepted || isRescheduleAccepted) && isProvider && serviceWithDeposit,
          isCompletePaymentRequested,
          isCompletePaymentCompleted,

          isAccepted,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return {
          headingState: 'unknown',

          showSecondPaymentSection: (isAccepted || isRescheduleAccepted) && isProvider,
          showRequestExtraPaymentButton:
            (isAccepted || isRescheduleAccepted) &&
            isProvider &&
            !isCompletePaymentRequested &&
            !isSecondPaymentRequested &&
            !isCustomProductService,
          isSecondPaymentRequested,
          isSecondPaymentCompleted,

          showCompletePaymentSection:
            (isAccepted || isRescheduleAccepted) && isProvider && serviceWithDeposit,
          showRequestCompletePaymentButton:
            (txJustAccepted || isRescheduleAccepted) && isProvider && serviceWithDeposit,
          isCompletePaymentRequested,
          isCompletePaymentCompleted,
        };
      }
    };

    const protectedData = currentTransaction?.attributes?.protectedData;

    const stateData = stateDataFn(currentTransaction);
    const offerAmount = protectedData?.offerAmount && Number(protectedData?.offerAmount);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction)}
        onDeclineSale={() => onDeclineSale(currentTransaction)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);
    const extraPaymentObject = protectedData?.extraPayment;
    const extraPaymentReceipt = extraPaymentObject?.receipt;
    const completePaymentObject = protectedData?.completePayment;
    const completePaymentReceipt = completePaymentObject?.receipt;

    const isTxAccepted =
      currentTransaction.attributes.lastTransition === 'transition/accept' ||
      currentTransaction.attributes.lastTransition === 'transition/accept-without-booking' ||
      currentTransaction.attributes.lastTransition === 'transition/accept-reschedule-by-provider' ||
      currentTransaction.attributes.lastTransition === 'transition/accept-reschedule-by-customer';
    const handleReschedule = (timeStart, timeEnd) => {
      const oldLessonDate = new Date(currentTransaction.booking.attributes.start).toDateString();
      return sdk.transactions
        .transition(
          {
            id: new UUID(currentTransaction.id.uuid),
            transition: isProvider
              ? 'transition/reschedule-by-provider'
              : 'transition/reschedule-by-customer',
            params: {
              protectedData: {
                rescheduleStart: new Date(timeStart).toString(),
                rescheduleEnd: new Date(timeEnd).toString(),
              },
            },
          },
          {
            expand: true,
          }
        )
        .then(resp => {
          return true;
        })
        .then(resp => {
          if (typeof window !== 'undefined') {
            window.location.reload();
          }
        })
        .catch(e => {
          this.setTimeNotAvailableError(true);
          console.log(e);
        });
    };

    const testDate = currentTransaction?.booking?.attributes?.displayStart;
    const currentDate = new Date();
    const questionsObj = currentTransaction?.attributes?.protectedData?.enquiryValues;

    //TODO #########################################
    //after figuring out how to handle variations, do not delete them
    if (questionsObj?.variation) {
      delete questionsObj?.variation;
    }

    const enquiryValues = questionsObj ? Object.entries(questionsObj) : [];
    const questionsArray = enquiryValues.filter(i => !i[0]?.includes('file-'));
    const filesArray = enquiryValues.filter(i => i[0]?.includes('file-'));

    const hoursUntilLessonStarts = testDate
      ? parseFloat(((testDate.getTime() - currentDate.getTime()) / 3600000).toFixed(1))
      : null;
    const rescheduleOfCancelDisabled = testDate
      ? isProvider
        ? hoursUntilLessonStarts < 24
        : hoursUntilLessonStarts < 4
      : null;
    // handle pending reschedule

    const getReadableDate = date => {
      return date.today() + ' ' + date.timeNow();
    };

    const isPendingRescheduleFromProvider =
      currentTransaction.attributes.lastTransition === 'transition/reschedule-by-provider';
    const isPendingRescheduleFromCustomer =
      currentTransaction.attributes.lastTransition === 'transition/reschedule-by-customer';
    const txProtectedData = currentTransaction.attributes.protectedData;
    const requestRescheduleTitle = ` ${
      isProvider ? 'Your client' : currentProvider?.attributes?.profile?.displayName
    } wants to reschedule for ${getReadableDate(
      new Date(txProtectedData.rescheduleStart)
    )} - ${getReadableDate(new Date(txProtectedData.rescheduleEnd))}`;

    const waitingRequestRescheduleTitle = `Waiting for reschedule approval for ${getReadableDate(
      new Date(txProtectedData.rescheduleStart)
    )} - ${getReadableDate(new Date(txProtectedData.rescheduleEnd))}`;

    const onAcceptReschedule = isProvider => {
      return sdk.transactions
        .transition(
          {
            id: new UUID(currentTransaction.id.uuid),
            transition: isProvider
              ? 'transition/accept-reschedule-by-provider'
              : 'transition/accept-reschedule-by-customer',
            params: {
              bookingStart: new Date(txProtectedData.rescheduleStart),
              bookingEnd: new Date(txProtectedData.rescheduleEnd),
            },
          },
          {
            expand: true,
          }
        )
        .then(resp => {
          return true;
        })
        .then(resp => {
          if (typeof window !== 'undefined') {
            window.location.reload();
          }
        })
        .catch(e => {
          this.setTimeNotAvailableError(true);
          console.log(e);
        });
    };

    // const now = new Date();
    // const bookingStart = currentTransaction.booking.attributes.start;

    // const cancelLimit = publicData.cancelLimit;
    // const diff = Math.floor((Date.parse(bookingStart) - Date.parse(now)) / 86400000);

    // const isButtonDisabled = diff < cancelLimit;

    // const lastTransitionAcceptOrConfirm =
    //   currentTransaction.attributes.lastTransition == 'transition/accept';

    const isVariablePrice = selectedService?.pricingType === 'variable';
    const variablePriceLabel =
      selectedService?.variablePriceUnit && ` per ${selectedService?.variablePriceUnit}`;
    const listingCategory = publicData?.category;
    const categoryConfig = findConfigForSelectFilter('category', config.custom.filters);
    const categories = categoryConfig.options ? categoryConfig.options : [];
    const categoryLabel = categories.find(c => c.key === listingCategory)?.label;

    if (isMessageTransaction) {
      return (
        <div className={css.messageTxWrapper}>
          <div className={css.messagesWrapper}>
            <div className={css.messagesLeft}>
              <div className={css.inboxBox}>INBOX</div>
            </div>
            <div className={css.messagesRight}>
              <div className={css.chatWrapper}>
                <FeedSection
                  rootClassName={css.feedContainer}
                  currentTransaction={currentTransaction}
                  currentUser={currentUser}
                  fetchMessagesError={fetchMessagesError}
                  fetchMessagesInProgress={fetchMessagesInProgress}
                  initialMessageFailed={initialMessageFailed}
                  messages={messages}
                  oldestMessagePageFetched={oldestMessagePageFetched}
                  onOpenReviewModal={this.onOpenReviewModal}
                  onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
                  totalMessagePages={totalMessagePages}
                  sendMessageForm={
                    <SendMessageForm
                      formId={this.sendMessageFormName}
                      rootClassName={css.sendMessageForm}
                      messagePlaceholder={sendMessagePlaceholder}
                      inProgress={sendMessageInProgress}
                      sendMessageError={sendMessageError}
                      onFocus={this.onSendMessageFormFocus}
                      onBlur={this.onSendMessageFormBlur}
                      onSubmit={this.onMessageSubmit}
                    />
                  }
                />
              </div>

              <div className={css.userInfoWrapper}>
                <div className={css.userInfoCard}>
                  <AvatarMedium
                    user={currentProvider}
                    className={css.userInfoCardAvatar}
                    oldAvatar={true}
                  />
                  <div className={css.listingInfo}>
                    <p className={css.listingInfoTitle}>{listingTitle}</p>
                    <p className={css.listingInfoCategory}>{categoryLabel}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const listingPublicData = currentListing?.attributes?.publicData || {};
    const firstImageUrl = firstImage?.attributes?.variants?.default?.url;
    const talentName = listingPublicData?.firstName + ' ' + listingPublicData?.lastInitial;
    const serviceName = selectedService?.name;
    const serviceDescription = selectedService?.description;
    console.log('SERVICE ->', selectedService);
    console.log('LISTING ->', currentListing);

    return (
      <div className={css.transactionContentWrapper}>
        <div className={css.transactionContainer}>
          <div className={css.txInfoSection}>
            <div className={css.txInfoSectionLeft}>
              <div className={css.txInfoSectionLeftTop}>
                <img src={firstImageUrl} className={css.avatarImage} />
                <div className={css.txInfoSectionDetails}>
                  <p className={css.txInfoSectionDetailsTitle}>{talentName}</p>
                  <p className={css.txInfoSectionDetailsBody}>{serviceName}</p>
                </div>

                <div className={css.txInfoSectionDetails}>
                  <p className={css.txInfoSectionDetailsTitle}>{'BOOKING STATUS'}</p>
                  <p className={css.txInfoSectionDetailsBody}>{stateData.headingState}</p>
                </div>
              </div>

              <div className={css.txInfoSectionLeftBottom}>
                {serviceDescription && (
                  <div className={css.txDescriptionSection}>
                    <p>{serviceDescription}</p>
                  </div>
                )}
              </div>
            </div>

            <div className={css.txInfoSectionRight}>
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanelTxPage}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  transaction={currentTransaction}
                  isCustomProductService={isCustomProductService}
                  selectedService={selectedService}
                />
              ) : null}

              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                breakdownTitle={
                  <p className={css.sectionHeading} style={{ marginTop: '0px' }}>
                    BREAKDOWN
                  </p>
                }
              />

              {stateData.showCompletePaymentSection && (
                <RequestCompletePaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onRequestCompletePayment={onRequestCompletePayment}
                  showRequestCompletePaymentButton={stateData.showRequestCompletePaymentButton}
                  isCompletePaymentRequested={stateData.isCompletePaymentRequested}
                />
              )}

              {stateData.isCompletePaymentRequested && isCustomer && (
                <HandleCompletePaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onCompletePayment={onCompletePayment}
                  authorDisplayName={authorDisplayName}
                />
              )}

              {stateData.showSecondPaymentSection && (
                <RequestExtraPaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onRequestSecondPayment={onRequestSecondPayment}
                  showRequestExtraPaymentButton={stateData.showRequestExtraPaymentButton}
                  isSecondPaymentRequested={stateData.isSecondPaymentRequested}
                />
              )}

              {stateData.isSecondPaymentRequested && isCustomer && (
                <HandleExtraPaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onSecondPayment={onSecondPayment}
                  authorDisplayName={authorDisplayName}
                />
              )}

              {extraPaymentReceipt && isCustomer && (
                <p style={{ marginTop: '10px' }}>
                  <a className={css.receipt} href={extraPaymentReceipt} target="_blank">
                    <ReceiptLongIcon /> See receipt for extra hours
                  </a>
                </p>
              )}

              {completePaymentReceipt && isCustomer && (
                <p style={{ marginTop: '10px' }}>
                  <a className={css.receipt} href={completePaymentReceipt} target="_blank">
                    <ReceiptLongIcon /> See receipt for leftover payment
                  </a>
                </p>
              )}

              {stateData.showWaitingForOffer && (
                <p className={css.pendingText}>Waiting for talent to make a price offer</p>
              )}

              {stateData.showOfferLabel && offerAmount && (
                <h2 className={css.offerPriceLabel}>
                  Price set for ${offerAmount} {isVariablePrice && variablePriceLabel}
                </h2>
              )}

              {stateData.showMakeOfferButton && (
                <MakeOfferModal onSendOffer={onSendOffer} currentTransaction={currentTransaction} />
              )}

              {isTxAccepted && !isCustomProductService ? ( // {isProvider && isTxAccepted ?
                <>
                  <div className={css.rescheduleWrapper}>
                    <SecondaryButton
                      disabled={rescheduleOfCancelDisabled}
                      onClick={() => {
                        this.setRescheduleModalOpen(true);
                      }}
                      className={css.shortButton}
                    >
                      {'Request to Reschedule'}
                    </SecondaryButton>

                    <Modal
                      isOpen={this.state.rescheduleModalOpen}
                      onClose={() => {
                        this.setRescheduleModalOpen(false);
                        this.setSubmitDisabled(true);
                      }}
                      onManageDisableScrolling={() => {}}
                    >
                      <div className={css.rescheduleModalWrapper}>
                        <center>
                          <h2>{'Pick a new date and time'}</h2>
                        </center>

                        <DateAndTimePicker
                          submitDisabled={this.state.submitDisabled}
                          setSubmitDisabled={this.setSubmitDisabled}
                          handleReschedule={handleReschedule}
                          listing={currentListing}
                          timeNotAvailableError={this.state.timeNotAvailableError}
                          intl={intl}
                        />
                      </div>
                    </Modal>
                  </div>

                  <div className={css.cancelWrapper}>
                    <SecondaryButton
                      disabled={rescheduleOfCancelDisabled}
                      onClick={() => onCancelSale(currentTransaction.id, isProvider)}
                      className={css.shortButton}
                    >
                      {'Cancel'}
                    </SecondaryButton>
                  </div>
                </>
              ) : null}

              {isPendingRescheduleFromCustomer ? (
                isProvider ? (
                  <>
                    <p className={css.requestRescheduleTitle}>{requestRescheduleTitle}</p>
                    <div className={css.cancelWrapper}>
                      <SecondaryButton onClick={() => onAcceptReschedule(isProvider)}>
                        {'Accept reschedule'}
                      </SecondaryButton>
                      <SecondaryButton
                        onClick={() => onCancelSale(currentTransaction.id, isProvider)}
                      >
                        {'Cancel'}
                      </SecondaryButton>
                    </div>
                  </>
                ) : (
                  <p className={css.requestRescheduleTitle}>{waitingRequestRescheduleTitle}</p>
                )
              ) : null}

              {isPendingRescheduleFromProvider ? (
                !isProvider ? (
                  <>
                    <p className={css.requestRescheduleTitle}>{requestRescheduleTitle}</p>
                    <div className={css.cancelWrapper}>
                      <SecondaryButton onClick={() => onAcceptReschedule(isProvider)}>
                        {'Accept reschedule'}
                      </SecondaryButton>
                      <SecondaryButton
                        onClick={() => onCancelSale(currentTransaction.id, isProvider)}
                      >
                        {'Cancel'}
                      </SecondaryButton>
                    </div>
                  </>
                ) : (
                  <p className={css.requestRescheduleTitle}>{waitingRequestRescheduleTitle}</p>
                )
              ) : null}

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </div>

          <div className={css.txPannel}>
            <p className={css.sectionHeading}>BOOKING DETAILS</p>
            <div className={css.questionsWrapper}>
              {questionsArray.map(q => {
                const linkedFile = filesArray.find(f => f[0]?.includes(q[0]));
                const fileName = linkedFile && linkedFile[1];
                console.log(fileName);
                return (
                  <div className={css.question}>
                    <p>
                      <strong>{q[0] && q[0].replaceAll('-', ' ')}</strong>
                    </p>
                    <p>{q[1]}</p>
                    {fileName && <PreviewFile fileName={fileName} />}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={css.messagesSection}>
            <p className={css.sectionHeading}>MESSAGES</p>

            <FeedSection
              rootClassName={css.feedContainerTxInfo}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              sendMessageForm={
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                />
              }
            />
          </div>

          {/* <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe transaction={currentTransaction} transactionRole={transactionRole} />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              sendMessageForm={
                <SendMessageForm
                  formId={this.sendMessageFormName}
                  rootClassName={css.sendMessageForm}
                  messagePlaceholder={sendMessagePlaceholder}
                  inProgress={sendMessageInProgress}
                  sendMessageError={sendMessageError}
                  onFocus={this.onSendMessageFormFocus}
                  onBlur={this.onSendMessageFormBlur}
                  onSubmit={this.onMessageSubmit}
                />
              }
            />

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />

              {stateData.showCompletePaymentSection && (
                <RequestCompletePaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onRequestCompletePayment={onRequestCompletePayment}
                  showRequestCompletePaymentButton={stateData.showRequestCompletePaymentButton}
                  isCompletePaymentRequested={stateData.isCompletePaymentRequested}
                />
              )}

              {stateData.isCompletePaymentRequested && isCustomer && (
                <HandleCompletePaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onCompletePayment={onCompletePayment}
                  authorDisplayName={authorDisplayName}
                />
              )}

              {stateData.showSecondPaymentSection && (
                <RequestExtraPaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onRequestSecondPayment={onRequestSecondPayment}
                  showRequestExtraPaymentButton={stateData.showRequestExtraPaymentButton}
                  isSecondPaymentRequested={stateData.isSecondPaymentRequested}
                />
              )}

              {stateData.isSecondPaymentRequested && isCustomer && (
                <HandleExtraPaymentModal
                  currentTransaction={currentTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isProvider={isProvider}
                  onSecondPayment={onSecondPayment}
                  authorDisplayName={authorDisplayName}
                />
              )}

              {extraPaymentReceipt && isCustomer && (
                <p style={{ marginTop: '10px' }}>
                  <a className={css.receipt} href={extraPaymentReceipt} target="_blank">
                    <ReceiptLongIcon /> See receipt for extra hours
                  </a>
                </p>
              )}

              {completePaymentReceipt && isCustomer && (
                <p style={{ marginTop: '10px' }}>
                  <a className={css.receipt} href={completePaymentReceipt} target="_blank">
                    <ReceiptLongIcon /> See receipt for leftover payment
                  </a>
                </p>
              )}

              {stateData.showWaitingForOffer && (
                <p className={css.pendingText}>Waiting for talent to make a price offer</p>
              )}

              {stateData.showOfferLabel && offerAmount && (
                <h2 className={css.offerPriceLabel}>
                  Price set for ${offerAmount} {isVariablePrice && variablePriceLabel}
                </h2>
              )}

              {stateData.showMakeOfferButton && (
                <MakeOfferModal onSendOffer={onSendOffer} currentTransaction={currentTransaction} />
              )}

              <div className={css.questionsWrapper}>
                {questionsArray.map(q => (
                  <div className={css.question}>
                    <p>
                      <strong>{q[0] && q[0].replaceAll('-', ' ')}</strong>
                    </p>
                    <p>{q[1]}</p>
                  </div>
                ))}
              </div>

              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  transaction={currentTransaction}
                  isCustomProductService={isCustomProductService}
                  selectedService={selectedService}
                />
              ) : null}

              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
              />

              {isTxAccepted && !isCustomProductService ? ( // {isProvider && isTxAccepted ?
                <>
                  <div className={css.rescheduleWrapper}>
                    <SecondaryButton
                      disabled={rescheduleOfCancelDisabled}
                      onClick={() => {
                        this.setRescheduleModalOpen(true);
                      }}
                    >
                      {'Request to Reschedule'}
                    </SecondaryButton>

                    <Modal
                      isOpen={this.state.rescheduleModalOpen}
                      onClose={() => {
                        this.setRescheduleModalOpen(false);
                        this.setSubmitDisabled(true);
                      }}
                      onManageDisableScrolling={() => {}}
                    >
                      <div className={css.rescheduleModalWrapper}>
                        <center>
                          <h2>{'Pick a new date and time'}</h2>
                        </center>

                        <DateAndTimePicker
                          submitDisabled={this.state.submitDisabled}
                          setSubmitDisabled={this.setSubmitDisabled}
                          handleReschedule={handleReschedule}
                          listing={currentListing}
                          timeNotAvailableError={this.state.timeNotAvailableError}
                          intl={intl}
                        />
                      </div>
                    </Modal>
                  </div>

                  <div className={css.cancelWrapper}>
                    <SecondaryButton
                      disabled={rescheduleOfCancelDisabled}
                      onClick={() => onCancelSale(currentTransaction.id, isProvider)}
                    >
                      {'Cancel'}
                    </SecondaryButton>
                  </div>
                </>
              ) : null}

              {isPendingRescheduleFromCustomer ? (
                isProvider ? (
                  <>
                    <p className={css.requestRescheduleTitle}>{requestRescheduleTitle}</p>
                    <div className={css.cancelWrapper}>
                      <SecondaryButton onClick={() => onAcceptReschedule(isProvider)}>
                        {'Accept reschedule'}
                      </SecondaryButton>
                      <SecondaryButton
                        onClick={() => onCancelSale(currentTransaction.id, isProvider)}
                      >
                        {'Cancel'}
                      </SecondaryButton>
                    </div>
                  </>
                ) : (
                  <p className={css.requestRescheduleTitle}>{waitingRequestRescheduleTitle}</p>
                )
              ) : null}

              {isPendingRescheduleFromProvider ? (
                !isProvider ? (
                  <>
                    <p className={css.requestRescheduleTitle}>{requestRescheduleTitle}</p>
                    <div className={css.cancelWrapper}>
                      <SecondaryButton onClick={() => onAcceptReschedule(isProvider)}>
                        {'Accept reschedule'}
                      </SecondaryButton>
                      <SecondaryButton
                        onClick={() => onCancelSale(currentTransaction.id, isProvider)}
                      >
                        {'Cancel'}
                      </SecondaryButton>
                    </div>
                  </>
                ) : (
                  <p className={css.requestRescheduleTitle}>{waitingRequestRescheduleTitle}</p>
                )
              ) : null}

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </div> */}
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
