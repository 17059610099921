import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../components';
import searchIconBlack from '../../assets/icons/searchIconBlack.png';
//icons
import messagesIcon from './icons/messagesIcon.png';
import savedIcon from './icons/savedIcon.png';
import servicesIcon from './icons/servicesIcon.png';
import settingsIcon from './icons/settingsIcon.png';
//
import css from './TopbarDesktopForInbox.module.css';
import { isTabSelected } from './utils';

const TopbarDesktopForInbox = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    transparent,
    params,
  } = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;

  const classes = classNames(rootClassName || css.root, className);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const isTalent = currentUser?.attributes?.profile?.protectedData?.profileType === 'talent';
  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel
        className={transparent ? css.profileMenuLabelTransparent : css.profileMenuLabel}
        isOpenClassName={css.profileMenuIsOpen}
      >
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {isTalent ? (
          <MenuItem key="NewListingPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('NewListingPage'))}
              name="NewListingPage"
            >
              <span className={css.menuItemBorder} />
              {currentUserHasListings ? (
                'Your profile'
              ) : (
                <FormattedMessage id="TopbarDesktop.newListingPage" />
              )}
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="empty-item"></MenuItem>
        )}
        {/* {isTalent ? (
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.yourListingsLink2" />
            </NamedLink>
          </MenuItem>
        ) : (
          <MenuItem key="empty-item"></MenuItem>
        )} */}

        {/* <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        {/* <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const accountSettingsPages = [
    'ContactDetailsPage',
    'PasswordChangePage',
    'StripePayoutPage',
    'PaymentMethodsPage',
  ];
  const isSettingsSelected = accountSettingsPages.includes(currentPage);
  const lastTab = isTalent ? (
    <NamedLink
      name={'ContactDetailsPage'}
      className={isSettingsSelected ? css.linkWrapperSelected : css.linkWrapper}
    >
      {' '}
      <img src={settingsIcon} className={css.settingsIcon} />
      <p className={css.linkLabel}>Settings</p>
    </NamedLink>
  ) : (
    <NamedLink
      name={'SavedPage'}
      className={isTabSelected('saved') ? css.linkWrapperSelected : css.linkWrapper}
    >
      {' '}
      <img src={savedIcon} className={css.linkIcon} />
      <p className={css.linkLabel}>Saved</p>
    </NamedLink>
  );
  return (
    <nav className={classes}>
      <div className={css.sectionTop}>
        <div className={css.sectionLeft}>
          <NamedLink className={css.logoLink} name="LandingPage">
            <Logo
              format="desktop"
              className={css.logo}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              transparent={transparent}
            />
          </NamedLink>
        </div>

        <div className={css.sectionCenter}>{lastTab}</div>

        <div className={css.sectionRight}>
          <div className={css.sectionRightItem}>
            {' '}
            <NamedLink className={css.searchIconNotTransparent} name={'SearchPage'}>
              <img className={css.seachIconBlack} src={searchIconBlack} />
            </NamedLink>
          </div>
          <div className={css.sectionRightItemForProfile}>{profileMenu}</div>
        </div>
      </div>
    </nav>
  );
};

TopbarDesktopForInbox.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktopForInbox.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktopForInbox;
