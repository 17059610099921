import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';

const { Money } = sdkTypes;

import css from './EditListingDescriptionPanel.module.css';
import { extractCity } from '../LocationAutocompleteInput/utils';
import { getSearchLocation } from './utils';
import { TalentCategories } from '../../marketplace-custom-config';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);

  const geolocation = currentListing?.attributes?.geolocation;
  const locationFieldsPresent =
    publicData && publicData.location && publicData.location.address && geolocation;
  const location = publicData && publicData.location ? publicData.location : {};
  const { address, building } = location;

  const defaultTimeZone = () =>
    typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

  const availabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
    ],
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{'Edit your profile'}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{
          // title,
          // description,
          // category: publicData.category,
          // subcategory: publicData.subcategory,
          // specialities: publicData.specialities ?? [undefined],
          // services: publicData.services ?? [{ faqs: [undefined] }],
          firstName: publicData.firstName,
          lastInitial: publicData.lastInitial,
          title,
          whatWeLoveAbout: publicData.whatWeLoveAbout,
          featuredQuote: publicData.featuredQuote,
          reviews: publicData.reviews || [undefined],
          searchTags: publicData.searchTags,
          building,
          location: locationFieldsPresent
            ? {
                search: address,
                selectedPlace: { address, origin: geolocation },
              }
            : null,
          virtualServices: publicData.virtualServices,
          category: publicData?.category,
          subcategories: publicData?.subcategories,
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            // title,
            // description,
            // category,
            // specialities,
            // services,
            // subcategory,
            firstName,
            lastInitial,
            title,
            whatWeLoveAbout,
            featuredQuote,
            reviews,
            searchTags,
            building = '',
            location,
            virtualServices,
            category,
            subcategories,
          } = values;
          const {
            selectedPlace: { address, origin },
          } = location;
          const price = new Money(0, config.currency);

          const searchLocation = getSearchLocation(address);

          // Only submit subcategories that are valid for the submitted category
          const validSubCategories = TalentCategories[category]?.subcategories?.map(
            subcategory => subcategory.key
          );

          let updateValues = {
            title: title.trim(),
            // description,
            price,
            geolocation: origin,
            availabilityPlan,
            publicData: {
              // category,
              // specialities,
              // services,
              // subcategory,
              firstName,
              lastInitial,
              whatWeLoveAbout,
              featuredQuote,
              reviews,
              searchTags,
              location: { address, building },
              virtualServices,
              category,
              subcategories: subcategories.filter(subcategoryValue =>
                validSubCategories.includes(subcategoryValue)
              ),
              ...searchLocation,
            },
          };

          // get the city name if any address present
          if (address) {
            return extractCity(address)
              .then(resp => {
                updateValues.publicData.location.address = resp;
                return onSubmit(updateValues);
              })
              .catch(e => {
                return onSubmit(updateValues);
              });
          }
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        certificateOptions={certificateOptions}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
