import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry, FieldSelect } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './EnquiryForm.module.css';
import CustomSelectField from './CustomSelectField/CustomSelectField';
import FileUploadField from '../../components/FilesUploadField/FileUploadField';

const EnquiryFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        focusedService,
        isMessage,
        fullName,
        isSpecificServiceInquiry,
        form,
      } = fieldRenderProps;

      const messageLabel = intl.formatMessage(
        {
          id: 'EnquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'EnquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;

      let customQuestions = focusedService?.questions || [];

      if (
        focusedService &&
        focusedService.typeOfService === 'experimental' &&
        !customQuestions.find(q => q?.question === 'What are you looking for?')
      ) {
        customQuestions.push({ question: 'What are you looking for?' });
      }

      if (
        focusedService &&
        focusedService.typeOfService === 'consultativeService' &&
        !customQuestions.find(
          q =>
            q?.question === 'What are you looking for?' ||
            q?.question === 'What is the address of the service?'
        )
      ) {
        customQuestions.push({ question: 'What are you looking for?' });
        customQuestions.push({ question: 'What is the address of the service?' });
      }

      if (
        focusedService &&
        focusedService.typeOfService === 'customProduct' &&
        !customQuestions.find(
          q => q?.question === 'What are you looking for?' || q?.question === 'Delivery info'
        )
      ) {
        customQuestions.push({ question: 'What are you looking for?' });
        customQuestions.push({ question: 'Delivery info' });
      }

      const variationOptions = focusedService?.variationOptions?.map((v, index) => {
        return {
          key: `${index}`,
          label: `${v.label} - $${v.price}`,
        };
      });

      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          {/* <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          /> */}

          {isMessage ? (
            <>
              <p className={css.title}>MESSAGE {fullName || authorDisplayName}</p>
              <div className={css.subTitle}>
                <p className={css.subTitleText}>
                  {isSpecificServiceInquiry ? (
                    <>
                      Have a question about {focusedService.name}?
                      <br />
                      Ask questions
                    </>
                  ) : (
                    <>
                      Don't see what you're looking for?
                      <br />
                      Ask questions
                    </>
                  )}
                </p>
                <p className={css.subTitleText}>
                  {isSpecificServiceInquiry ? focusedService.name : 'Custom service inquiry'}
                </p>
              </div>

              <FieldTextInput
                className={css.inquiryField}
                type="textarea"
                name="message"
                id={formId ? `${formId}.message` : 'message'}
                // label={messageLabel}
                placeholder={'Type here'}
                validate={messageRequired}
              />

              <div className={css.submitButtonsWrapper}>
                {sendEnquiryError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EnquiryForm.sendEnquiryError" />
                  </p>
                ) : null}
                <PrimaryButton
                  className={css.inquiryButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {'Send'}
                </PrimaryButton>
              </div>
            </>
          ) : (
            <>
              {variationOptions?.length > 0 && (
                <CustomSelectField id="variation" name="variation" options={variationOptions} />
              )}
              {customQuestions.map(q => {
                const index = customQuestions.findIndex(x => x?.question === q?.question);
                return (
                  <>
                    <FieldTextInput
                      className={css.question}
                      type="text"
                      // name={`question${index}`}
                      name={q?.question?.replaceAll(' ', '-') || 'no-question'}
                      id={formId ? `${formId}.message` : 'message'}
                      label={q?.question}
                      placeholder={'Type here...'}
                      // validate={messageRequired}
                    />

                    <FileUploadField
                      id={`file-${q?.question?.replaceAll(' ', '-')}`}
                      name={`file-${q?.question?.replaceAll(' ', '-')}`}
                      remove={() => form.change(`file-${q?.question?.replaceAll(' ', '-')}`, null)}
                    />
                  </>
                );
              })}

              <div className={css.submitWrapper}>
                {sendEnquiryError ? (
                  <p className={css.error}>
                    <FormattedMessage id="EnquiryForm.sendEnquiryError" />
                  </p>
                ) : null}
                <PrimaryButton
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {isMessage ? 'Send message' : 'CONTINUE'}
                </PrimaryButton>
              </div>
            </>
          )}
        </Form>
      );
    }}
  />
);

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

EnquiryForm.displayName = 'EnquiryForm';

export default EnquiryForm;
