import React, { useState, useEffect, useRef } from 'react';
import { Field } from 'react-final-form';
import arrow from '../../../assets/icons/arrowDown.png';

import css from './CustomSelectField.module.css';

const CustomSelectField = props => {
  const { name, id, label, options } = props;

  const CustomSelectFieldComponent = ({ input: { onChange, value }, label, ...rest }) => {
    const wrapperRef = useRef(null);
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value || null);

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setShowOptions(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [wrapperRef]);

    const selectedOptionLabel =
      (selectedOption && options.find(o => o.key === selectedOption)?.label) || 'SELECT AN OPTION';
    return (
      <div
        className={css.selectInput}
        onClick={() => setShowOptions(!showOptions)}
        ref={wrapperRef}
      >
        <div className={css.selectedOption}>{selectedOptionLabel}</div>
        <img src={arrow} className={css.arrow} />
        {showOptions && (
          <div className={css.options}>
            {options.map((o, index) => {
              const isFirst = index === 0;
              const isLast = index === options.length - 1;
              let optionClassName = css.option;
              if (isFirst) {
                optionClassName = css.firstOption;
              }
              if (isLast) {
                optionClassName = css.lastOption;
              }
              return (
                <div
                  className={optionClassName}
                  onClick={() => {
                    onChange(o.key);
                    setSelectedOption(o.key);
                  }}
                >
                  {o.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return <Field name={name} label={label} id={id} component={CustomSelectFieldComponent} />;
};

export default CustomSelectField;
