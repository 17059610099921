import {
  REQUEST_TO_BOOK,
  SEND_CONCIERGE_QUESTION,
} from '../containers/ListingPage/ListingPage.duck';
import {
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from '../ducks/Auth.duck';
import { LOCATION_CHANGED } from '../ducks/Routing.duck';
import { parse } from '../util/urlHelpers';

const ACTIONS_TO_RECORD = [
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
];

// Create a Redux middleware from the given analytics handlers. Each
// handler should have the following methods:
//
// - trackPageView(canonicalPath, previousPath): called when the URL is changed
// - trackConciergeQuestions(currentPage, listing, service): called when a concierge question is submitted
export const createMiddleware = handlers => store => next => action => {
  const { type, payload } = action;

  if (type === LOCATION_CHANGED) {
    const previousPath = store?.getState()?.Routing?.currentCanonicalPath;
    const { canonicalPath, location } = payload;
    handlers.forEach(handler => {
      handler.trackPageView(canonicalPath, previousPath, parse(location?.search));
    });
  } else if (type === SEND_CONCIERGE_QUESTION) {
    const { service, listing } = payload ?? {};
    const currentPage = store?.getState()?.Routing?.currentCanonicalPath;
    const listingInfoForAnalytics = listing
      ? { listingId: listing.id.uuid, category: listing.attributes.publicData.category }
      : {};
    const serviceDataForAnalytics = service
      ? { serviceType: service.typeOfService, serviceName: service.name }
      : {};
    handlers.forEach(handler => {
      handler.trackConciergeQuestions(
        currentPage,
        listingInfoForAnalytics,
        serviceDataForAnalytics
      );
    });
  } else if (type === REQUEST_TO_BOOK) {
    const { service, listing } = payload ?? {};
    const currentPage = store?.getState()?.Routing?.currentCanonicalPath;
    const listingInfoForAnalytics = listing
      ? { listingId: listing.id.uuid, category: listing.attributes.publicData.category }
      : {};
    const serviceDataForAnalytics = service
      ? { serviceType: service.typeOfService, serviceName: service.name }
      : {};
    handlers.forEach(handler => {
      handler.trackRequestToBook(currentPage, listingInfoForAnalytics, serviceDataForAnalytics);
    });
  } else if (ACTIONS_TO_RECORD.includes(type)) {
    const currentPage = store?.getState()?.Routing?.currentCanonicalPath;
    handlers.forEach(handler => handler.trackGeneralAction(currentPage, type));
  }

  next(action);
};
