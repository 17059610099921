import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, IconEnquiry } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './MessageForm.module.css';

const MessageFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        authorDisplayName,
        sendEnquiryError,
        focusedService,
        isMessageSuccess,
        onClose,
      } = fieldRenderProps;

      const messageRequiredMessage = intl.formatMessage({
        id: 'EnquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;
      return (
        <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
          {!isMessageSuccess ? (
            <>
              <p className={css.title}>MESSAGE OUR CONCIERGE</p>
              <div className={css.subTitle}>
                <div className={css.leftSubTitleText}>
                  <p className={css.subTitleText}>Have any questions?</p>
                  <p className={css.subTitleText}>Chat with our team and see how they can help</p>
                </div>
                <div className={css.rightSubTitleText}>
                  <p className={css.subTitleTextRight}>{focusedService?.name}</p>
                  <p className={css.subTitleTextRight}>{authorDisplayName}</p>
                </div>
              </div>
              {sendEnquiryError && (
                <p className={css.error}>
                  There was an error sending your message, please try again or email
                  concierge@ihavethisfriend.co directly!
                </p>
              )}
              <FieldTextInput
                className={css.inquiryField}
                type="textarea"
                name="message"
                id={formId ? `${formId}.message` : 'message'}
                // label={messageLabel}
                placeholder={'TYPE HERE'}
                validate={messageRequired}
              />

              <div className={css.submitButtonsWrapper}>
                <PrimaryButton
                  className={css.inquiryButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  {'Send'}
                </PrimaryButton>
              </div>
            </>
          ) : (
            <>
              <p className={css.title}>MESSAGE SENT</p>
              <div className={css.subTitle}>
                <p className={css.subTitleText}>
                  We've received your message! We'll take a look and get back to you ASAP. Keep an
                  eye on your inbox for a response from concierge@ihavethisfriend.co.
                </p>
              </div>
              <div className={css.submitButtonsWrapper}>
                <PrimaryButton type="close" onClick={onClose}>
                  {'Close'}
                </PrimaryButton>
              </div>
            </>
          )}
        </Form>
      );
    }}
  />
);

MessageFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

MessageFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const MessageForm = compose(injectIntl)(MessageFormComponent);

MessageForm.displayName = 'MessageForm';

export default MessageForm;
