import React from 'react';
import css from './CancellationsAndFeesSection.module.css';

const CancellationsAndFeesSection = () => (
  <div className={css.detailsSection}>
    <div className={css.detailsElement}>
      <span className={css.detailsSectionTitle}>Cancellation Policy</span>
      <p className={css.detailsSectionText}>
        We understand that life happens and schedules change. Out of respect and protection of
        talent time and materials, we require that you cancel your service 48 hours or more in
        advance to receive a full refund. Canceling within 48 hours of your service being performed
        will result in a fee equal to 50% of the cost of your service.
        <br />
        <br />
        To reschedule a service, we ask that you do so 24 hours or more in advance of the service
        being performed to avoid a 15% inconvenience charge. If you reschedule within 24 hours of
        the service being performed, you will be charged a 15% inconvenience fee.
        <br />
        <br />
        Rescheduling and then canceling a service will result in a fee equal to 50% of the cost of
        your service.
      </p>
    </div>
    <div>
      <p className={css.detailsSectionTitle}>Payments and Fees</p>
      <p className={css.detailsSectionText}>
        Once your service is complete, you'll receive payment details from us via email (powered by
        Stripe). Your invoice will reflect the final cost of services rendered and a 5% processing
        fee. This helps keep things running smoothly at IHTF and makes it possible for us to give
        you, our community, the best experience possible—from platform curation, to customer
        support, to nurturing the careers of talented creatives, and so much more. Thank you for the
        support!
        <br />
        <br />
        Please note: Gratuity is not necessary. Our pricing is set by the talent themselves and
        reflects fair compensation for their services.
      </p>
    </div>
  </div>
);

export default CancellationsAndFeesSection;
