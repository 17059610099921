import React, { useState } from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import css from './HandleExtraPaymentModal.module.css';
import CardSection from '../CardSection/CardSection';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { post } from '../../../util/api';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const HandleExtraPaymentModal = props => {
  const {
    currentTransaction,
    showSendTipsButton,
    onManageDisableScrolling,
    isProvider,
    onSecondPayment,
    authorDisplayName,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [stripe, setStripe] = useState(false);
  const [card, setCard] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const destinationUserId = currentTransaction?.provider?.id?.uuid;
  const protectedData = currentTransaction?.attributes?.protectedData;
  const extraPaymentObject = protectedData?.extraPayment;

  const handleStripeToken = (event, stripe, elements) => {
    const card = elements.getElement(CardElement);

    if (event.complete) {
      setSubmitReady(true);
    }
    setCard(card);
    setStripe(stripe);
  };

  const submit = () => {
    setLoading(true);

    return stripe
      .createToken(card)
      .then(resp => {
        const token = resp.token;
        const body1 = {
          token,
          finalAmount: extraPaymentObject.amount,
          destinationUserId,
          transaction: currentTransaction,
          description: `Payment of $${extraPaymentObject.amount} for and extra ${
            extraPaymentObject.hours
          } ${Number(extraPaymentObject.hours) > 1 ? 'hours' : 'hour'}
           Listing id: ${currentTransaction.listing.id.uuid} 
           Transaction id: ${currentTransaction.id.uuid}
          `,
        };

        return post('/api/handle-second-payment', body1)
          .then(resp => {
            const receipt = resp;

            const data = {
              extraPayment: {
                hours: extraPaymentObject.hours,
                amount: extraPaymentObject.amount,
                receipt,
              },
            };

            return onSecondPayment(currentTransaction.id.uuid, data)
              .then(resp => {
                setLoading(false);
                setSubmitted(true);
                setSuccess(true);
                setError(false);
              })
              .catch(e => {
                setLoading(false);
                setError(true);
                console.log(e);
              });
          })
          .catch(e => {
            setLoading(false);
            setError(true);
            console.log(e);
          });
      })
      .catch(e => {
        setLoading(false);
        setError(true);
        console.log(e);
      });
  };

  const errorMessage = 'Woops! Something went wrong. Please try again.';
  const successMessage = 'Congratulations! Your payment was successful!';
  const submitDisabled = !submitReady;

  return (
    <div className={css.tipsSectionWrapper}>
      <p className={css.pendingRequest}>
        {authorDisplayName} requested ${extraPaymentObject.amount} for extra{' '}
        {extraPaymentObject.hours} {Number(extraPaymentObject.hours) > 1 ? 'hours' : 'hour'}
      </p>
      <Button className={css.sendTipButton} type="button" onClick={() => setModalOpen(true)}>
        Pay
      </Button>

      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div>
          <center>
            <h2>Confirm payment</h2>
          </center>
          <div className={css.breakdownWrapper}>
            <div className={css.lineItemLabel}>
              {extraPaymentObject.hours} {Number(extraPaymentObject.hours) > 1 ? 'hours' : 'hour'}
            </div>
            <div className={css.lineItemValue}>${extraPaymentObject.amount}</div>
          </div>
          <div className={css.cardSectionWrapper}>
            <CardSection handleStripeToken={handleStripeToken} />
          </div>

          {error ? <p className={css.error}>{errorMessage}</p> : null}
          {success ? <p className={css.success}>{successMessage}</p> : null}
          <Button
            className={css.submitTipButton}
            disabled={submitDisabled || submitted || loading}
            type="button"
            onClick={submit}
          >
            {submitted ? (
              <CheckIcon />
            ) : loading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress className={css.spinnerMiddle} />
              </Box>
            ) : (
              'Pay'
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default HandleExtraPaymentModal;
