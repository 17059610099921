/**
 * TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  AvatarLarge,
  InlineTextButton,
  Logo,
  NamedLink,
  NotificationBadge,
  OwnListingLink,
} from '../../components';

import css from './TopbarMobileMenu.module.css';
import { TalentCategories, TalentGroups } from '../../marketplace-custom-config';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const [talentCategoriesOpen, setTalentCategoriesOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);

  // if (!isAuthenticated) {
  //   const signup = (
  //     <NamedLink name="SignupPage" className={css.signupLink}>
  //       <FormattedMessage id="TopbarMobileMenu.signupLink" />
  //     </NamedLink>
  //   );

  //   const login = (
  //     <NamedLink name="LoginPage" className={css.loginLink}>
  //       <FormattedMessage id="TopbarMobileMenu.loginLink" />
  //     </NamedLink>
  //   );

  //   const signupOrLogin = (
  //     <span className={css.authenticationLinks}>
  //       <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
  //     </span>
  //   );
  //   return (
  //     <div className={css.root}>
  //       <div className={css.content}>
  //         <div className={css.authenticationGreeting}>
  //           <FormattedMessage
  //             id="TopbarMobileMenu.unauthorizedGreeting"
  //             values={{ lineBreak: <br />, signupOrLogin }}
  //           />
  //         </div>
  //       </div>
  //       <div className={css.footer}>
  //         <NamedLink className={css.createNewListingLink} name="NewListingPage">
  //           <FormattedMessage id="TopbarMobileMenu.newListingLink" />
  //         </NamedLink>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className={css.root}>
      {' '}
      <NamedLink className={css.mobileMenuTitle} name="LandingPage">
        <Logo className={css.logo} transparent={false} />
      </NamedLink>{' '}
      <div className={css.mobileMenuLinks}>
        <a onClick={() => setTalentCategoriesOpen(!talentCategoriesOpen)}>Book Talent</a>
        <div
          className={talentCategoriesOpen ? css.extendedMenuSection : css.extendedMenuSectionHidden}
        >
          {Object.values(TalentGroups).map(group => (
            <a
              key={group.label}
              className={css.talentGroup}
              href={`/s?pub_category=${group.categories.map(category => category.key).join(',')}`}
            >
              {group.label}
            </a>
          ))}
          <a key={'searchAll'} className={css.talentGroup} href={`/s`}>
            Search All
          </a>
        </div>
        <a onClick={() => setSettingsMenuOpen(!settingsMenuOpen)}>Settings</a>
        <div className={settingsMenuOpen ? css.extendedMenuSection : css.extendedMenuSectionHidden}>
          <a href="/account/contact-details">My Account</a>
          <a href="/account/change-password">Change Password</a>
        </div>
        <a href="/about">About</a>
        {/* <a href="/blog">Blog</a> */}
        <a href="/faqs">FAQ</a>
      </div>
      {isAuthenticated && <a onClick={onLogout}>Sign Out</a>}
      {!isAuthenticated && (
        <div>
          <a href="/login">Log In</a>
        </div>
      )}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
