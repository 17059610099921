import React, { useState, useRef } from 'react';
import axios from 'axios';
import css from './InboxFilesUploadField.module.css';
import { Field } from 'react-final-form';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { limitText } from './utils';
import AddIcon from '@mui/icons-material/Add';
import ImagePreview from './ImagePreview/ImagePreview';
import Button from '../Button/Button';
const fileDownload = require('js-file-download');

const InboxFilesUploadField = ({ name, id, remove }) => {
  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';
  const fileInputRef = useRef(null);

  const uploadField = ({ input: { onChange, value }, label, ...rest }) => {
    const onFileChange = event => {
      const files = event.target.files || [];

      const promises = Array.from(files).map(file => {
        const formData = new FormData();

        formData.append('image', file, file.name);
        return axios
          .post(`${isDev ? 'http://localhost:3500' : ''}/api/azure-upload`, formData)
          .then(resp => {
            const file = resp?.data?.file;
            return file?.name;
          })
          .catch(error => {
            console.log(error);
          });
      });

      return Promise.all(promises)
        .then(resp => {
          onChange(resp);
        })
        .catch(e => console.log(e));
    };

    if (value) {
      const uploadedFiles = value.map(v => {
        return { fileName: v?.split('-')[1], fullFileName: v };
      });

      const handleDownloadFile = selectedFile => {
        axios
          .get(
            `${isDev ? 'http://localhost:3500' : ''}/api/azure-download?fileName=` + selectedFile,
            {
              responseType: 'blob',
            }
          )
          .then(resp => {
            fileDownload(resp.data, `${selectedFile}`);
          })
          .catch(error => {
            console.log(error);
          });
      };

      const handleDeleteFiles = () => {
        const promises = uploadedFiles.map(f => {
          return axios
            .get(
              `${isDev ? 'http://localhost:3500' : ''}/api/azure-delete?fileName=` +
                f?.fullFileName,
              {
                responseType: 'blob',
              }
            )
            .catch(e => console.log(e));
        });

        return Promise.all(promises)
          .then(resp => {
            remove();
          })
          .catch(e => {
            remove();
          });
      };
      return (
        <div className={css.uploadedFilesWrapper}>
          <div className={css.filesWrapper}>
            {' '}
            {uploadedFiles.map(f => {
              return (
                <ImagePreview fieldLabel={` `} fileName={f?.fullFileName} />
                // <div
                //   className={css.fileIcon}
                //   title="download"
                //   onClick={() => handleDownloadFile(f?.fullFileName)}
                // >
                //   <FolderIcon className={css.icon} />
                //   {limitText(f?.fileName, 15)}
                // </div>
              );
            })}
          </div>

          <div className={css.actionButtonsWrapper}>
            <Button className={css.actionButton} type="submit">
              Send
            </Button>{' '}
            <Button className={css.actionButton} type="button" onClick={handleDeleteFiles}>
              Cancel
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={css.wrapper}>
        <input
          type="file"
          multiple
          className={css.hiddenFileInput}
          onChange={onFileChange}
          ref={fileInputRef}
        />

        <div className={css.addFileButton} onClick={() => fileInputRef.current.click()}>
          <AddIcon />
        </div>
      </div>
    );
  };

  return <Field id={id} name={name} component={uploadField} />;
};

export default React.memo(InboxFilesUploadField);
