import classNames from 'classnames';
import { string } from 'prop-types';
import React, { useState } from 'react';
import {
  ExternalLink,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  NamedLink,
} from '../../components';
import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { twitterPageURL } from '../../util/urlHelpers';
import css from './Footer.module.css';
import igIcon from './Icons/igIcon.png';
import tiktokIcon from './Icons/tiktokIcon.png';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const [referModalOpen, setReferModalOpen] = useState(false);
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.footerWrapper}>
        <div className={css.content}>
          {/* <div className={css.newsletterSection}>
            <NewsletterForm />
          </div> */}
          {/* <div className={css.separator}></div> */}
          <div className={css.linksSection}>
            <div className={css.submenu}>
              <p className={css.submenuLabel}>NAVIGATE</p>
              <NamedLink name={'AboutPage'} className={css.submenuItemLink}>
                ABOUT
              </NamedLink>
              <NamedLink name={'SearchPage'} className={css.submenuItemLink}>
                BOOK TALENT
              </NamedLink>
              {/* <NamedLink name={'BlogHomePage'} className={css.submenuItemLink}>
                BLOG
              </NamedLink> */}
              <NamedLink name={'FAQPage'} className={css.submenuItemLink}>
                FAQ
              </NamedLink>
              <NamedLink name={'PrivacyPolicyPage'} className={css.submenuItemLink}>
                PRIVACY POLICY
              </NamedLink>
            </div>

            <div className={css.submenu}>
              <p className={css.submenuLabel}>SOCIAL</p>
              <div className={css.submenuItem}>
                <div className={css.socialIcons}>
                  <a href="https://www.instagram.com/ihavethisfriend/" target="_blank">
                    <img src={igIcon} className={css.icon} />
                  </a>
                  <a href="https://www.tiktok.com/@ihavethisfriend_" target="_blank">
                    <img src={tiktokIcon} className={css.icon} />
                  </a>
                </div>
              </div>
            </div>
            <div className={css.submenu}>
              <p className={css.submenuLabel}>CONTACT US</p>
              <a className={css.submenuItem} href="mailto:hello@ihavethisfriend.co">
                {/* DROP US A NOTE ANYTIME:
                <br /> */}
                <strong className={css.submenuItemLink}>Client Support</strong>
              </a>
              <a className={css.submenuItem} href="mailto:concierge@ihavethisfriend.co">
                {/* DROP US A NOTE ANYTIME:
                <br /> */}
                <strong className={css.submenuItemLink}>Concierge</strong>
              </a>
              <a
                className={css.submenuItem}
                href="https://ihavethisfeedback.paperform.co/"
                target="_blank"
              >
                {/* DROP US A NOTE ANYTIME:
                <br /> */}
                <strong className={css.submenuItemLink}>Website Feedback</strong>
              </a>
              {/* <div className={css.faqsButton}>FAQS</div> */}
            </div>
            <div className={css.submenu}>
              <p className={css.referralText}>Know someone super talented?</p>
              <a
                href={'https://ihavethisfriend.paperform.co/'}
                target="_blank"
                // onClick={() => setReferModalOpen(true)}
                type="button"
                className={css.referButton}
              >
                REFER TALENT
              </a>
              <p className={css.referralText}>Are you super talented?</p>
              <a
                href={'https://ihavethistalent.paperform.co/'}
                target="_blank"
                type="button"
                className={css.referButton}
              >
                APPLY HERE
              </a>
            </div>
          </div>
        </div>
        {/* <div className={css.bottomSection}>
          <p className={css.bottomLink}>ALL RIGHTS RESERVED</p>
          <p className={css.bottomLink}>TERMS OF USE</p>
          <p className={css.bottomLink}>PRIVACY POLICY</p>
        </div> */}
      </div>
      {/* <ReferModal referModalOpen={referModalOpen} setReferModalOpen={setReferModalOpen} /> */}
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
