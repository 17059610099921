import React, { useState } from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import css from './MakeOfferModal.module.css';

const MakeOfferModal = props => {
  const { onSendOffer, currentTransaction } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [priceValue, setPriceValue] = useState(null);

  const handlePriceChange = e => {
    const value = e.target.value;
    function containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    }
    if (containsOnlyNumbers(value)) {
      setPriceValue(value);
    }
  };

  const offerButtonLabel = priceValue ? `Send offer for $${priceValue}` : 'Send offer';

  const handleSendOffer = () => {
    if (priceValue) {
      return onSendOffer(currentTransaction, priceValue);
    }
  };
  return (
    <div className={css.mainWrapper}>
      <Button onClick={() => setModalOpen(true)} className={css.offerButton}>
        Make price offer
      </Button>
      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onManageDisableScrolling={() => {}}
      >
        <div className={css.modalContentWrapper}>
          <h2>Price offer in dollars</h2>

          <input
            className={css.offerInput}
            type="text"
            placeholder="100"
            value={priceValue}
            onChange={handlePriceChange}
          />

          <Button onClick={handleSendOffer} className={css.sendOfferButton} disabled={!priceValue}>
            {offerButtonLabel}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default MakeOfferModal;
