import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import css from './ImagePreview.module.css';
import DownloadIcon from '@mui/icons-material/Download';

const fileDownload = require('js-file-download');

const ImagePreview = ({ fieldLabel, fileName: propsFileName }) => {
  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';
  const fileInputRef = useRef(null);

  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [fileName, setFileName] = useState(propsFileName);

  useEffect(() => {
    if (fileName) {
      axios
        .get(`${isDev ? 'http://localhost:3500' : ''}/api/azure-download?fileName=` + fileName, {
          responseType: 'blob',
        })
        .then(resp => {
          const imageUrl = URL.createObjectURL(resp.data);
          setPreviewImageUrl(imageUrl);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [fileName, isDev]);

  const handleDownloadFile = e => {
    e.preventDefault();
    e.stopPropagation();
    axios
      .get(`${isDev ? 'http://localhost:3500' : ''}/api/azure-download?fileName=` + fileName, {
        responseType: 'blob',
      })
      .then(resp => {
        fileDownload(resp.data, `${fileName}`);
      })
      .catch(error => {
        console.log(error);
      });
  };

  if (previewImageUrl) {
    return (
      <div className={css.fileWrapper} onClick={handleDownloadFile}>
        <img src={previewImageUrl} alt="Preview Image" className={css.previewImage} />
      </div>
    );
  }

  return null;
};

export default React.memo(ImagePreview);
