export const getSearchLocation = str => {
  if (!str) {
    return {};
  }
  const lowercaseStr = str.toLowerCase();

  if (lowercaseStr.includes('los angeles')) {
    return { searchLocation: 'losAngeles' };
  } else if (lowercaseStr.includes('new york')) {
    return { searchLocation: 'newYork' };
  } else {
    return {};
  }
};
