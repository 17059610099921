import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import coverVideo from './promoVideo.mp4';
import fallbackImage from '../../containers/LandingPage/landingPageFallback.png';

import css from './SectionHero.module.css';
import WaterfallSearchForm from './WaterfallSearchForm';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, history } = props;

  const videoRef = React.useRef(null);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.backgroundVideo}>
        <video
          className={showVideo ? css.videoActive : css.videoHidden}
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          onPlay={() => {
            setShowVideo(true);
          }}
        >
          <source src={coverVideo} type="video/mp4" />
        </video>
        <img className={showVideo ? css.imageHidden : css.imageActive} src={fallbackImage}></img>
      </div>

      <div className={css.darkOverlay}></div>
      <div className={css.heroContent}>
        <WaterfallSearchForm history={history} />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
