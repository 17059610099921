import React from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Button } from '..';
import CustomSelectFormField from '../CustomSelectFormField/CustomSelectFormField';
import { findConfigForSelectFilter } from '../../util/search';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { copyArrayOrObject } from '../../util/data';
import css from './WaterfallSearchForm.module.css';
import { TalentCategories, TalentLocations } from '../../marketplace-custom-config';

const getFilteredSubCategories = category => {
  return copyArrayOrObject(TalentCategories[category]?.subcategories) ?? [];
};

function WaterfallSearchForm(props) {
  const { history } = props;
  const onSubmit = values => {
    const { category, subcategory, location } = values;

    let searchParams = {};

    if (category) {
      searchParams.pub_category = category;
    }

    if (subcategory) {
      // This will find all listings with this subcategory in the array of subcategories assigned to it
      // see https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchParams.pub_subcategories = subcategory;
    }

    if (location) {
      if (location === 'virtual') {
        searchParams.pub_virtualServices = 'yes';
      }
      searchParams.pub_searchLocation = location;
    }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  let subCategoryOptions;

  const onChange = formValues => {
    if (formValues.values.category) {
      subCategoryOptions = getFilteredSubCategories(formValues.values.category);
    }
  };

  const categoryOptions = Object.values(TalentCategories).map(option => {
    return {
      key: option.key,
      label: option.homepageLabel,
    };
  });
  const locationOptions = Object.values(TalentLocations);

  return (
    <div className={css.formWrapper}>
      <FinalForm
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const { handleSubmit, values } = fieldRenderProps;
          subCategoryOptions = getFilteredSubCategories(values.category);

          // If the subcategory options have changed and the currently chose subcategory is no longer valid
          // then we null out the subcategory
          if (!subCategoryOptions.find(sc => sc.key === values.subcategory)) {
            values.subcategory = null;
          }
          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
              className={css.formWrapper}
            >
              <FormSpy onChange={onChange} />
              <div className={css.formLine}>
                <p className={css.formLineLabel}> I'm looking for</p>
                <CustomSelectFormField
                  id="category"
                  name="category"
                  className={css.homepageSelectField}
                  options={categoryOptions}
                />
              </div>

              <div className={css.formLine}>
                <p className={css.formLineLabel}>Who can help me with</p>
                <CustomSelectFormField
                  id="subcategory"
                  name="subcategory"
                  options={subCategoryOptions}
                />
              </div>

              <div className={css.formLine}>
                {values?.location !== TalentLocations.Virtual.key && (
                  <p className={css.formLineLabel}>in</p>
                )}
                <CustomSelectFormField id="location" name="location" options={locationOptions} />
              </div>

              <Button className={css.submit} type="submit">
                DISCOVER
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}

export default WaterfallSearchForm;
