import React, { useState, useRef } from 'react';
import axios from 'axios';
import css from './FileUploadField.module.css';
import { Field } from 'react-final-form';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { limitText } from './utils';
const fileDownload = require('js-file-download');

const FileUploadField = ({ name, id, remove, uploadButtonClassName }) => {
  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';
  const fileInputRef = useRef(null);

  const uploadField = ({ input: { onChange, value }, label, ...rest }) => {
    const onFileChange = event => {
      const formData = new FormData();

      formData.append('image', event.target.files[0], event.target.files[0].name);
      return axios
        .post(`${isDev ? 'http://localhost:3500' : ''}/api/azure-upload`, formData)
        .then(resp => {
          const file = resp?.data?.file;
          onChange(file?.name);
        })
        .catch(error => {
          console.log(error);
        });
    };

    if (value) {
      const fileName = value.split('-')[1];

      const handleDownloadFile = () => {
        axios
          .get(`${isDev ? 'http://localhost:3500' : ''}/api/azure-download?fileName=` + value, {
            responseType: 'blob',
          })
          .then(resp => {
            fileDownload(resp.data, `${value}`);
          })
          .catch(error => {
            console.log(error);
          });
      };

      const handleDeleteFile = e => {
        e.stopPropagation();
        e.preventDefault();
        remove();

        return axios.get(
          `${isDev ? 'http://localhost:3500' : ''}/api/azure-delete?fileName=` + value,
          {
            responseType: 'blob',
          }
        );
      };
      return (
        <div className={css.fileIcon} title="download" onClick={handleDownloadFile}>
          <DeleteIcon className={css.deleteIcon} onClick={handleDeleteFile} />
          <FolderIcon className={css.icon} />
          {limitText(fileName, 15)}
        </div>
      );
    }

    return (
      <div className={css.wrapper}>
        <input
          type="file"
          className={css.hiddenFileInput}
          onChange={onFileChange}
          ref={fileInputRef}
        />

        <div
          className={uploadButtonClassName || css.addFileButton}
          onClick={() => fileInputRef.current.click()}
        >
          UPLOAD FILE
          {/* <DriveFolderUploadIcon id="upload-icon" fontSize="large" className={css.addFileIcon} /> */}
        </div>
      </div>
    );
  };

  return <Field id={id} name={name} component={uploadField} />;
};

export default FileUploadField;
