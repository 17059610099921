import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, FieldCheckboxGroup } from '../../components';

import config from '../../config';
import arrayMutators from 'final-form-arrays';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';

import { autocompleteSearchRequired, autocompletePlaceSelected } from '../../util/validators';
import { LocationAutocompleteInputField } from '../../components';

import { copyArrayOrObject, countriesArray } from '../../util/data';
import CustomFieldEnum from '../../components/CustomFieldEnum/CustomFieldEnum';
import { findConfigForSelectFilter } from '../../util/search';
import css from './EditListingDescriptionForm.module.css';
import { TalentCategories } from '../../marketplace-custom-config';

const TITLE_MAX_LENGTH = 60;
const identity = v => v;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
        form: {
          mutators: { push },
        },
        values,
      } = formRenderProps;
      const titleMessage = intl.formatMessage({ id: 'EditListingDetailsForm.title2' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titlePlaceholder2',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDetailsForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.descriptionPlaceholder2',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const dynamicMaxLength = max =>
        maxLength(
          intl.formatMessage(
            { id: 'EditListingDetailsForm.maxLength' },
            {
              maxLength: max,
            }
          ),
          max
        );

      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDetailsForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDetailsForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const categories = Object.values(TalentCategories);

      const titleLocationRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.address',
      });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const virtualServicesOptions = [
        {
          key: 'yes',
          label: 'Virtual Services Offered',
        },
        {
          key: 'no',
          label: 'Virtual Services Not Offered',
        },
      ];
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <div className={css.sectionWrapper}>
            <center>
              <h2 className={css.sectionTitle}>Overview</h2>
            </center>

            <FieldTextInput
              id="firstName"
              name="firstName"
              className={css.title}
              type="text"
              label={'First name'}
              placeholder={'JANE'}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required('You need to complete this field'))}
            />

            <FieldTextInput
              id="lastInitial"
              name="lastInitial"
              className={css.title}
              type="text"
              label={'Last initial'}
              placeholder={'A'}
              maxLength={1}
              validate={composeValidators(required('You need to complete this field'))}
            />

            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={'Job Title'}
              placeholder={'INTERIOR DESIGNER, PRIVATE CHEF, PHOTOGRAPHER'}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus={autoFocus}
            />

            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              labelClassName={css.inputLabel}
              validClassName={css.validLocation}
              autoFocus
              name="location"
              label={'City'}
              placeholder={'LOS ANGELES, NEW YORK'}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <FieldCheckboxGroup
              label={'Virtual Services'}
              className={css.checkboxGroup}
              id={'virtualServices'}
              name={'virtualServices'}
              options={virtualServicesOptions}
              radio={true}
              twoColumns={true}
            />

            <FieldTextInput
              id="whatWeLoveAbout"
              name="whatWeLoveAbout"
              className={css.title}
              type="text"
              label={`What we love about them (${values?.whatWeLoveAbout?.length || 0}/530)`}
              placeholder={'TYPE HERE:'}
              validate={composeValidators(
                required('You need to complete this field'),
                dynamicMaxLength(530)
              )}
            />

            <FieldTextInput
              id="featuredQuote"
              name="featuredQuote"
              className={css.title}
              type="text"
              label={`Featured Quote (${values?.featuredQuote?.length || 0}/110)`}
              placeholder={'TYPE HERE:'}
              validate={composeValidators(
                required('You need to complete this field'),
                dynamicMaxLength(110)
              )}
            />
          </div>

          <div className={css.sectionWrapperDynamicFields}>
            <center>
              <h2 className={css.sectionTitle}>What Clients are Saying (Reviews):</h2>
            </center>

            <div className={css.dynamicFieldsLabel}>
              Add a review
              <AddIcon className={css.addButton} onClick={() => push('reviews', undefined)} />
            </div>

            <div className={css.dynamicFieldsWrapper}>
              <FieldArray name="reviews">
                {({ fields }) =>
                  fields.map((name, index) => {
                    const reviewLength =
                      values.reviews[index] && values.reviews[index]?.review?.length;
                    return (
                      <div className={css.dynamicFieldWrapper} key={name}>
                        <div className={css.closeIconWrapper}>
                          <CloseIcon
                            onClick={() => fields.remove(index)}
                            style={{ color: '#827D8D', cursor: 'pointer' }}
                          />
                        </div>
                        {/* <center>
                        <p className={css.reviewLabel}>Review</p>
                      </center> */}
                        <label className={css.dynamicItemLabel}>
                          Review {`(${reviewLength || 0}/105)`}
                        </label>
                        <FieldTextInput
                          name={`${name}.review`}
                          className={css.dynamicFieldTextInput}
                          // component="input"
                          placeholder={'TYPE HERE'}
                          validate={composeValidators(dynamicMaxLength(105))}
                        />

                        <label className={css.dynamicItemLabel}>Name of Reviewer</label>
                        <Field
                          name={`${name}.nameOfReviewer`}
                          className={css.dynamicField}
                          component="input"
                          placeholder={'FIRST NAME LAST INITIAL ( JANE D. )'}
                        />
                      </div>
                    );
                  })
                }
              </FieldArray>
            </div>
          </div>

          <div className={css.sectionWrapper}>
            <center>
              <h2 className={css.sectionTitle}>Metadata</h2>
            </center>

            <FieldTextInput
              id="searchTags"
              name="searchTags"
              className={css.title}
              type="text"
              label={'Search tags'}
              placeholder={'#TAG'}
              validate={composeValidators(required('You need to complete this field'))}
            />

            <label className={css.fieldLabel}>Category</label>
            <FieldSelect name={'category'}>
              <option value="" disabled hidden>
                Pick something...
              </option>
              {categories.map(o => {
                return <option value={o.key}>{o.label}</option>;
              })}
            </FieldSelect>

            {values.category && (
              <>
                <br />
                <label className={css.fieldLabel}>Specialties</label>
                <FieldCheckboxGroup
                  {...{
                    name: 'subcategories',
                    id: `subcategories-selection`,
                    validate: requiredFieldArrayCheckbox('this is required'),
                    options: TalentCategories[values.category]?.subcategories,
                    twoColumns: true,
                  }}
                />
              </>
            )}
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
