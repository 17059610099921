import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import * as startOfDay from 'date-fns';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './DateAndTimePicker.module.css';
import './TimePicker.css';
import StartTime from './StartTime';
import EndTime from './EndTime';
const { UUID } = sdkTypes;

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

Date.prototype.substractHours = function(h) {
  this.setTime(this.getTime() - h * 60 * 60 * 1000);
  return this;
};

import { Button } from '../../components';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export default function MaterialUIPickers(props) {
  const {
    submitDisabled,
    setSubmitDisabled,
    handleReschedule,
    listing,
    timeNotAvailableError,
    intl,
  } = props;

  useEffect(() => {
    const startDate = new Date();
    const endDate = startDate.addDays(90);

    sdk.timeslots
      .query({
        listingId: new UUID(listing.id.uuid),
        start: startDate,
        end: endDate,
      })
      .then(res => {
        setTimeslots(res.data.data);
        const avTimeslots = res.data.data;

        const firstAvDate = new Date(avTimeslots[0].attributes.start);
        setDate(firstAvDate);
        // setTimeStart(firstAvDate);
        // setTimeEnd(firstAvDate);
      })
      .catch(e => console.log(e));
  }, []);

  const [date, setDate] = useState(new Date());
  const [timeslots, setTimeslots] = useState([]);

  const [startTimeValue, setStartTimeValue] = useState('');
  const [endTimeValue, setEndTimeValue] = useState('');

  const handleChangeDate = newValue => {
    setDate(newValue);
    setSubmitDisabled(false);
  };

  const shouldDisableDate = date => {
    const dateFound = timeslots.find(slot => {
      const slotStart = new Date(slot.attributes.start).getDate();
      const slotEnd = new Date(slot.attributes.end).getDate();
      const testedDate = date.getDate();

      const slotMonth = new Date(slot.attributes.start).getMonth();
      const testedDateMonth = date.getMonth();

      return slotStart <= testedDate && testedDate <= slotEnd && slotMonth === testedDateMonth;
    });

    return !dateFound;
  };

  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DatePicker
          label="Date"
          inputFormat="dd/MM/yyyy"
          value={date}
          onChange={handleChangeDate}
          renderInput={params => <TextField {...params} />}
          shouldDisableDate={shouldDisableDate}
          disablePast={true}
        />

        <StartTime
          intl={intl}
          timeZone={timeZone}
          bookingStart={date}
          timeslots={timeslots}
          startTimeValue={startTimeValue}
          setStartTimeValue={setStartTimeValue}
        />

        <EndTime
          intl={intl}
          timeZone={timeZone}
          bookingStart={date}
          timeslots={timeslots}
          startTimeValue={startTimeValue}
          endTimeValue={endTimeValue}
          setEndTimeValue={setEndTimeValue}
        />

        {/* <TimePicker
          label="Start time"
          value={timeStart}
          onChange={handleChangeTimeStart}
          shouldDisableTime={shouldDisableTimeStart}
          renderInput={(params) => <TextField {...params} />}
          ampm={false}
        /> */}
        {/* 
        <TimePicker
          label="End time"
          value={timeEnd}
          onChange={handleChangeTimeEnd}
          shouldDisableTime={shouldDisableTimeEnd}
          renderInput={(params) => <TextField {...params} />}
          ampm={false}
        /> */}
        {timeNotAvailableError ? (
          <center>
            <p className={css.warningText}>
              Woops! It seems like you picked a time that is not available, please pick another time
              and try again
            </p>
          </center>
        ) : null}

        <Button
          disabled={submitDisabled}
          onClick={() => {
            return handleReschedule(startTimeValue, endTimeValue);
          }}
        >
          Submit
        </Button>
      </Stack>
    </LocalizationProvider>
  );
}
