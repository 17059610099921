import PropTypes from 'prop-types';
import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  return <iframe src="/static/ihtf-privacy-policy.pdf" height="100vh" width="100%"></iframe>;
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
