import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  getStartHours,
  isInRange,
  resetToStartOfDay,
  dateIsAfter,
} from '../../util/dates';
import './TimePicker.css';

export default function StartTime(props) {

  const { intl, timeZone, bookingStart, timeslots, startTimeValue, setStartTimeValue } = props;


  const handleChange = (event) => {
    setStartTimeValue(event.target.value);
  };


  const getTimeSlots = (timeSlots, date, timeZone) => {
    return timeSlots && timeSlots[0]
      ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
      : [];
  };


  const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate) => {
    if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
      return [];
    }
    const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);
  
    const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
      const startDate = t.attributes.start;
      const endDate = t.attributes.end;
      const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);
  
      // If the start date is after timeslot start, use the start date.
      // Otherwise use the timeslot start time.
      const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;
  
      // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
      // Otherwise use the end of the timeslot.
      const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;
  
      const hours = getStartHours(intl, timeZone, startLimit, endLimit);
      return availableHours.concat(hours);
    }, []);
    return allHours
  };


  const timeSlotsOnSelectedDate = getTimeSlots(
    timeslots,
    bookingStart,
    timeZone
  );

  const availableStartTimes = getAvailableStartTimes(
    intl,
    timeZone,
    bookingStart,
    timeSlotsOnSelectedDate
  );

availableStartTimes.pop()

// console.log('start times', availableStartTimes)

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Start time</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={startTimeValue}
          label="Start time"
          onChange={handleChange}
        >
          {availableStartTimes.map(i => {

            return(
              <MenuItem value={i.timestamp}>{i.timeOfDay}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  );
}