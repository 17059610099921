/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const TalentSubCategories = {
  editClosetOrganizer: {
    key: 'editClosetOrganizer',
    label: 'Editing My Closet',
  },
  springCleaning: {
    key: 'springCleaning',
    label: 'Spring Cleaning',
  },
  bigMove: {
    key: 'bigMove',
    label: 'A Big Move',
  },
  garage: {
    key: 'garage',
    label: 'Making my garage functional',
  },
  dinnerPartyFood: {
    key: 'dinnerPartyFood',
    label: 'A Dinner party with friends',
  },
  mealPrep: {
    key: 'mealPrep',
    label: 'Meal Prep',
  },
  weddingFood: {
    key: 'weddingFood',
    label: 'a wedding',
  },
  celebrationFood: {
    key: 'celebrationFood',
    label: 'a celebration',
  },
  dateNight: {
    key: 'dateNightFood',
    label: 'date night',
  },
  specialCake: {
    key: 'specialCake',
    label: 'a special cake',
  },
  cookingClass: {
    key: 'cookingClass',
    label: 'cooking class',
  },
  omakase: {
    key: 'omakase',
    label: 'at-home omakase',
  },
  weddingFlowers: {
    key: 'weddingFlowers',
    label: 'a wedding',
  },
  bouquet: {
    key: 'bouquet',
    label: 'A special bouquet',
  },
  centerpiece: {
    key: 'centerpiece',
    label: 'A seasonal centerpiece',
  },
  celebrationFlowers: {
    key: 'celebrationFlowers',
    label: 'a large celebration',
  },
  giftFlowers: {
    key: 'giftFlowers',
    label: 'A thoughtful gift',
  },
  dinnerPartyFlowers: {
    key: 'dinnerPartyFlowers',
    label: 'Dinner party decor',
  },
  floralWorkshop: {
    key: 'floralWorkshop',
    label: 'a floral workshop',
  },
  photoshootStyling: {
    key: 'photoshootStyling',
    label: 'A photoshoot',
  },
  weddingStyling: {
    key: 'weddingStyling',
    label: 'Looks for my wedding',
  },
  capsuleWardrobe: {
    key: 'capsuleWardrobe',
    label: 'Building a capsule wardrobe',
  },
  editingClosetStyling: {
    key: 'editingClosetStyling',
    label: 'editing my closet',
  },
  eventStyling: {
    key: 'eventStyling',
    label: 'finding the perfect outfit for an event',
  },
  workStyling: {
    key: 'workStyling',
    label: 'what to wear to work',
  },
  vacationStyling: {
    key: 'vacationStyling',
    label: 'vacation styling',
  },
  vintageClothing: {
    key: 'vintageClothing',
    label: 'repurposing vintage finds',
  },
  upcycledBridal: {
    key: 'upcycledBridal',
    label: 'An upcycled vintage bridal look',
  },
  designFromScratch: {
    key: 'designFromScratch',
    label: 'designing a piece from scratch',
  },
  artClass: {
    key: 'artClass',
    label: 'an art class',
  },
  liveEventArt: {
    key: 'liveEventArt',
    label: 'live event art',
  },
  comissionedArt: {
    key: 'comissionedArt',
    label: 'a commissioned piece',
  },
  customStationery: {
    key: 'customStationery',
    label: 'personal stationery',
  },
  customMenus: {
    key: 'customMenus',
    label: 'custom menus',
  },
  celebrationPlanning: {
    key: 'celebrationPlanning',
    label: 'a large celebration',
  },
  weddingInvitations: {
    key: 'weddingInvitations',
    label: 'save the dates and wedding invitations',
  },
  giftArt: {
    key: 'giftArt',
    label: 'a thoughtful gift',
  },
  themedGathering: {
    key: 'themedGathering',
    label: 'a themed gathering',
  },
  dinnerPartyPlanning: {
    key: 'dinnerPartyPlanning',
    label: 'a dinner party',
  },
  weddingPlanning: {
    key: 'weddingPlanning',
    label: 'a wedding',
  },
  holidayParty: {
    key: 'holidayParty',
    label: 'a holiday party',
  },
  customTablescape: {
    key: 'customTablescape',
    label: 'a custom tablescape',
  },
  picnic: {
    key: 'picnic',
    label: 'a picnic',
  },
  honeymoon: {
    key: 'honeymoon',
    label: 'my honeymoon',
  },
  nextBigTrip: {
    key: 'nextBigTrip',
    label: 'planning my next big trip',
  },
  italianTrip: {
    key: 'italianTrip',
    label: 'an italian itinerary',
  },
  weekendGetaway: {
    key: 'weekendGetaway',
    label: 'a weekend getaway',
  },
  familyVacation: {
    key: 'familyVacation',
    label: 'a family vacation',
  },
  groupTrip: {
    key: 'groupTrip',
    label: 'a group trip',
  },
  proposalPhotos: {
    key: 'proposalPhotos',
    label: 'a proposal',
  },
  partyPhotos: {
    key: 'partyPhotos',
    label: 'a party',
  },
  bigWeddingPhotos: {
    key: 'bigWeddingPhotos',
    label: 'a big wedding',
  },
  smallWeddingPhotos: {
    key: 'smallWeddingPhotos',
    label: 'an elopement or intimate ceremony',
  },
  headshots: {
    key: 'headshots',
    label: 'headshots',
  },
  familyPortrait: {
    key: 'familyPortrait',
    label: 'a family portrait',
  },
  galleryWall: {
    key: 'galleryWall',
    label: 'designing a gallery wall',
  },
  sourcingArt: {
    key: 'sourcingArt',
    label: 'sourcing art',
  },
  artInvestment: {
    key: 'artInvestment',
    label: 'investing in my first piece',
  },
  framingAdvice: {
    key: 'framingAdvice',
    label: 'framing advice',
  },
  findingUniquePieces: {
    key: 'findingUniquePieces',
    label: 'finding unique pieces',
  },
  decoratingNewHome: {
    key: 'decoratingNewHome',
    label: 'decorating a new home',
  },
  roomUpgrade: {
    key: 'roomUpgrade',
    label: 'a room upgrade',
  },
  stylingSpace: {
    key: 'stylingSpace',
    label: 'styling my space',
  },
  designAdvice: {
    key: 'designAdvice',
    label: 'quick design advice',
  },
  weddingMakeup: {
    key: 'weddingMakeup',
    label: 'wedding day makeup',
  },
  eventMakeup: {
    key: 'eventMakeup',
    label: 'nailing an event look',
  },
  liveEventMakeup: {
    key: 'liveEventMakeup',
    label: 'live event makeup',
  },
  freshChop: {
    key: 'freshChop',
    label: 'a fresh chop',
  },
  weddingHair: {
    key: 'weddingHair',
    label: 'wedding day hair',
  },
  dinnerPartyAstrology: {
    key: 'dinnerPartyAstrology',
    label: 'a dinner party activity',
  },
  bacheloretteAstrology: {
    key: 'bacheloretteAstrology',
    label: 'a bachelorette',
  },
  findingClarity: {
    key: 'findingClarity',
    label: 'finding clarity',
  },
  partyActivityAstrology: {
    key: 'partyActivityAstrology',
    label: 'party activity',
  },
};

export const TalentCategories = {
  commissionedArtist: {
    key: 'commissionedArtist',
    label: 'Artist',
    homepageLabel: 'An Artist',
    subcategories: [
      TalentSubCategories.artClass,
      TalentSubCategories.liveEventArt,
      TalentSubCategories.comissionedArt,
      TalentSubCategories.customStationery,
      TalentSubCategories.customMenus,
      TalentSubCategories.weddingInvitations,
      TalentSubCategories.giftArt,
    ],
  },
  privateChef: {
    key: 'privateChef',
    label: 'Private Chef',
    homepageLabel: 'A Private Chef',
    subcategories: [
      TalentSubCategories.dinnerPartyFood,
      TalentSubCategories.mealPrep,
      TalentSubCategories.weddingFood,
      TalentSubCategories.celebrationFood,
      TalentSubCategories.dateNight,
      TalentSubCategories.cookingClass,
      TalentSubCategories.specialCake,
      TalentSubCategories.omakase,
    ],
  },
  photographer: {
    key: 'photographer',
    label: 'Photographer',
    homepageLabel: 'A Photographer',
    subcategories: [
      TalentSubCategories.proposalPhotos,
      TalentSubCategories.partyPhotos,
      TalentSubCategories.bigWeddingPhotos,
      TalentSubCategories.smallWeddingPhotos,
      TalentSubCategories.headshots,
      TalentSubCategories.familyPortrait,
    ],
  },
  eventPlanner: {
    key: 'eventPlanner',
    label: 'Event Planner',
    homepageLabel: 'An Event Planner',
    subcategories: [
      TalentSubCategories.celebrationPlanning,
      TalentSubCategories.themedGathering,
      TalentSubCategories.holidayParty,
      TalentSubCategories.dinnerPartyPlanning,
      TalentSubCategories.weddingPlanning,
      TalentSubCategories.customTablescape,
      TalentSubCategories.picnic,
    ],
  },
  interiorDesigner: {
    key: 'interiorDesigner',
    label: 'Interior Designer',
    homepageLabel: 'An Interior Designer',
    subcategories: [
      TalentSubCategories.findingUniquePieces,
      TalentSubCategories.decoratingNewHome,
      TalentSubCategories.roomUpgrade,
      TalentSubCategories.stylingSpace,
      TalentSubCategories.designAdvice,
    ],
  },
  professionalOrganizer: {
    key: 'professionalOrganizer',
    label: 'Professional Organizer',
    homepageLabel: 'A Professional Organizer',
    subcategories: [
      TalentSubCategories.editClosetOrganizer,
      TalentSubCategories.springCleaning,
      TalentSubCategories.bigMove,
      TalentSubCategories.garage,
    ],
  },
  stylist: {
    key: 'stylist',
    label: 'Stylist',
    homepageLabel: 'A Stylist',
    subcategories: [
      TalentSubCategories.photoshootStyling,
      TalentSubCategories.weddingStyling,
      TalentSubCategories.capsuleWardrobe,
      TalentSubCategories.editingClosetStyling,
      TalentSubCategories.eventStyling,
      TalentSubCategories.workStyling,
      TalentSubCategories.vacationStyling,
    ],
  },
  clothingDesigner: {
    key: 'clothingDesigner',
    label: 'Clothing Designer',
    homepageLabel: 'A Clothing Designer',
    subcategories: [
      TalentSubCategories.vintageClothing,
      TalentSubCategories.upcycledBridal,
      TalentSubCategories.designFromScratch,
    ],
  },
  travelAdvisor: {
    key: 'travelAdvisor',
    label: 'Travel Advisor',
    homepageLabel: 'A Travel Advisor',
    subcategories: [
      TalentSubCategories.honeymoon,
      TalentSubCategories.nextBigTrip,
      TalentSubCategories.italianTrip,
      TalentSubCategories.weekendGetaway,
      TalentSubCategories.familyVacation,
      TalentSubCategories.groupTrip,
    ],
  },
  florist: {
    key: 'florist',
    label: 'Florist',
    homepageLabel: 'A Florist',
    subcategories: [
      TalentSubCategories.weddingFlowers,
      TalentSubCategories.bouquet,
      TalentSubCategories.centerpiece,
      TalentSubCategories.celebrationFlowers,
      TalentSubCategories.giftFlowers,
      TalentSubCategories.dinnerPartyFlowers,
      TalentSubCategories.floralWorkshop,
    ],
  },
  artAdvisor: {
    key: 'artAdvisor',
    label: 'Art Advisor',
    homepageLabel: 'An Art Advisor',
    subcategories: [
      TalentSubCategories.galleryWall,
      TalentSubCategories.sourcingArt,
      TalentSubCategories.artInvestment,
      TalentSubCategories.framingAdvice,
    ],
  },
  makeupArtist: {
    key: 'makeupArtist',
    label: 'Makeup Artist',
    homepageLabel: 'A Makeup Artist',
    subcategories: [
      TalentSubCategories.weddingMakeup,
      TalentSubCategories.eventMakeup,
      TalentSubCategories.liveEventMakeup,
    ],
  },
  hairStylist: {
    key: 'hairStylist',
    label: 'Hairstylist',
    homepageLabel: 'A Hairstylist',
    subcategories: [TalentSubCategories.freshChop, TalentSubCategories.weddingHair],
  },
  astrologer: {
    key: 'astrologer',
    label: 'Astrologer',
    homepageLabel: 'An Astrologer',
    subcategories: [
      TalentSubCategories.dinnerPartyAstrology,
      TalentSubCategories.bacheloretteAstrology,
      TalentSubCategories.findingClarity,
      TalentSubCategories.partyActivityAstrology,
    ],
  },
};

export const TalentGroups = {
  Interiors: {
    label: 'interiors',
    categories: [TalentCategories.interiorDesigner, TalentCategories.professionalOrganizer],
  },
  Events: {
    label: 'events',
    categories: [
      TalentCategories.privateChef,
      TalentCategories.photographer,
      TalentCategories.florist,
      TalentCategories.eventPlanner,
    ],
  },
  Style: {
    label: 'style',
    categories: [
      TalentCategories.clothingDesigner,
      TalentCategories.stylist,
      TalentCategories.hairStylist,
      TalentCategories.makeupArtist,
    ],
  },
  Food: {
    label: 'food',
    categories: [TalentCategories.privateChef],
  },
  Art: {
    label: 'art',
    categories: [TalentCategories.artAdvisor, TalentCategories.commissionedArtist],
  },
  Travel: {
    label: 'travel',
    categories: [TalentCategories.travelAdvisor],
  },
  Gifting: {
    label: 'gifting',
    categories: [
      TalentCategories.florist,
      TalentCategories.commissionedArtist,
      TalentCategories.travelAdvisor,
      TalentCategories.artAdvisor,
      TalentCategories.clothingDesigner,
      TalentCategories.astrologer,
      TalentCategories.privateChef,
      TalentCategories.professionalOrganizer,
      TalentCategories.interiorDesigner,
    ],
  },
};

export const ParamNames = {
  VirtualServicesParam: 'pub_virtualServices',
  LocationParam: 'pub_searchLocation',
  CategoryParam: 'pub_category',
};

export const TalentLocations = {
  LA: {
    key: 'losAngeles',
    label: 'Los Angeles',
    paramName: ParamNames.LocationParam,
  },
  NY: {
    key: 'newYork',
    label: 'New York',
    paramName: ParamNames.LocationParam,
  },
  Virtual: {
    key: 'virtual',
    label: 'Virtual',
    paramName: ParamNames.VirtualServicesParam,
  },
};

export const filters = [
  {
    id: 'searchLocation',
    label: 'Location',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: [ParamNames.LocationParam],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: Object.values(TalentLocations),
    },
  },
  {
    id: 'category',
    label: 'Service Type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: [ParamNames.CategoryParam],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: Object.values(TalentCategories),
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
