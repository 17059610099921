import React from 'react';
import PropTypes from 'prop-types';

import config from '../../config';
import logoImageMobile from './blackLogo.png';
import logoImage from './logoBlue.png';
import logoTransparent from './logoTransparent.png';

const Logo = props => {
  const { className, format, transparent, ...rest } = props;

  return (
    <img
      className={className}
      src={transparent ? logoTransparent : logoImage}
      alt={config.siteTitle}
      {...rest}
    />
  );
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
