export const filterResponseForOnlyMessages = response => {
  let newResponse = { ...response };

  newResponse.data.data = newResponse.data.data.filter(
    tx => tx?.attributes?.protectedData?.enquiryValues?.message
  );
  newResponse.data.meta.totalItems = newResponse.data.data?.length;

  return newResponse;
};

export const filterResponseForOnlyOrders = response => {
  let newResponse = { ...response };

  newResponse.data.data = newResponse.data.data.filter(
    tx => !!!tx?.attributes?.protectedData?.enquiryValues?.message
  );
  newResponse.data.meta.totalItems = newResponse.data.data?.length;

  return newResponse;
};
