import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { post } from '../../util/api';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import ReactDomServer from 'react-dom/server';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ServiceDetailsPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ServiceDetailsPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ServiceDetailsPage/SHOW_LISTING_ERROR';

export const SEND_CONCIERGE_QUESTION = 'app/ServiceDetailsPage/SEND_CONCIERGE_QUESTION';
export const REQUEST_TO_BOOK = 'app/ServiceDetailsPage/REQUEST_TO_BOOK';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  getListing: null,
  getServiceDetails: null,
};

const serviceDetailsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };
    case SEND_CONCIERGE_QUESTION:
      return { ...state, sendConciergeQuestionInProgress: true, sendEnquiryError: null };

    default:
      return state;
  }
};

export default serviceDetailsPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const sendConciergeQuestion = (listing, service) => ({
  type: SEND_CONCIERGE_QUESTION,
  payload: {
    listing,
    service,
  },
});

export const requestToBook = (listing, service) => ({
  type: REQUEST_TO_BOOK,
  payload: {
    listing,
    service,
  },
});

// ================ Thunks ================ //

export const showListing = listingId => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',
      'variants.default',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = sdk.listings.show(params);
  console.log(show);

  return show
    .then(data => {
      console.log(data);
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const onSendConciergeQuestion = (
  focusedService,
  currentListing,
  customerInfo,
  message
) => dispatch => {
  const htmlFormat = (
    <div>
      <h1>Message to concierge via website</h1>
      <div>
        {`Customer Info:`}
        <ul>
          <li>{`Email: ${customerInfo.attributes?.email}`}</li>
          <li>{`Phone Number: ${customerInfo.attributes?.profile?.protectedData?.phoneNumber}`}</li>
          <li>{`Name: ${customerInfo.attributes?.profile?.firstName} ${customerInfo?.attributes?.profile?.lastName}`}</li>
        </ul>
      </div>
      <br></br>
      {currentListing && (
        <div>
          {`Listing Info:`}
          <ul>
            <li>{`URL: ${window.location.href}`}</li>
            <li>{`Talent Name: ${currentListing.author?.attributes?.profile?.displayName}`}</li>
          </ul>
          <br></br>
        </div>
      )}
      {focusedService && (
        <div>
          {`Service Info:`}
          <ul>
            <li>{`Name: ${focusedService.name}`}</li>
            <li>{`Description: ${focusedService.description}`}</li>
          </ul>
          <br></br>
        </div>
      )}
      <div>
        <strong>Customer Message:</strong>
        {` ${message}`}
      </div>
    </div>
  );
  const body = {
    subject: `Website Concierge Request`,
    html: ReactDomServer.renderToString(htmlFormat),
    to: 'concierge@ihavethisfriend.co',
  };

  return post('/api/sendgrid-email', body).then(() => {
    dispatch(sendConciergeQuestion(currentListing, focusedService));
  });
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);
  console.log(`load data ${JSON.stringify(listingId)}`);

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  return dispatch(showListing(listingId));
};
