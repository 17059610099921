import React, { Component, useEffect, useState } from 'react';
import { array, string, func } from 'prop-types';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink } from '../../components';
import { propTypes } from '../../util/types';
//images
import bookmarked from './icons/bookmarked.png';
import notBookmarked from './icons/notBookmarked.png';
import hoverPic from './images/hoverPic.jpg';
//style
import css from './ListingCardSimple.module.css';
import { composeDescription } from './utils';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const ListingCardSimpleComponent = props => {
  const { listing, currentUser, imageClassName } = props;

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);
  const listingId = currentListing && listing.id?.uuid;
  const [isFavourite, setIsFavourite] = useState(false);

  useEffect(() => {
    if (
      currentUser?.id?.uuid &&
      currentUser.attributes.profile.privateData &&
      currentUser.attributes.profile.privateData.favListingsArray
    ) {
      const listingsIdsArray = currentUser.attributes.profile.privateData.favListingsArray;
      const isFavourite = listingsIdsArray.find(id => {
        return id === listingId;
      });
      if (isFavourite) {
        setIsFavourite(true);
      }
    }
  }, [currentUser]);

  const handleSave = e => {
    e.preventDefault();
    if (!isFavourite) {
      return sdk.currentUser
        .show()
        .then(resp => {
          const updatedUser = resp.data.data;
          const favListingsIds = updatedUser.attributes.profile.privateData.favListingsArray || [];

          return sdk.currentUser
            .updateProfile({
              privateData: {
                favListingsArray: [...favListingsIds, listingId],
              },
            })
            .then(resp => {
              return setIsFavourite(true);
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    } else {
      return sdk.currentUser
        .show()
        .then(resp => {
          const updatedUser = resp.data.data;
          const favListingsIds = updatedUser.attributes.profile.privateData.favListingsArray;

          const favListingsArrayCopy = [...favListingsIds];

          for (var i = 0; i < favListingsArrayCopy.length; i++) {
            if (favListingsArrayCopy[i] === listingId) {
              favListingsArrayCopy.splice(i, 1);
            }
          }

          return sdk.currentUser
            .updateProfile({
              privateData: {
                favListingsArray: favListingsArrayCopy,
              },
            })
            .then(resp => {
              return setIsFavourite(false);
            })
            .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
    }
  };

  const l = listing; //temporary, later replace all "l" with "listing"
  const listingDesciption =
    l.attributes.description && l.attributes.description.length > 60
      ? l.attributes.description.slice(0, 57) + '...'
      : l.attributes.description;

  const listingAddress = listing?.attributes?.publicData?.location?.address;
  const virtualServices = listing?.attributes?.publicData?.virtualServices === 'yes';

  const locationInfoTitle = listingAddress + (virtualServices ? ', VIRTUAL' : '');

  const publicData = l?.attributes?.publicData;
  const images = l?.images || [];

  const headshotPhotoIds = publicData?.headshotPhotoIds || [];
  const featuredWorkPhotoIds = publicData?.featuredWorkPhotoIds || [];

  const headshotPhoto = images.find(i => headshotPhotoIds.includes(i?.id?.uuid));
  const featuredWorkPhoto = images.find(i => featuredWorkPhotoIds.includes(i?.id?.uuid));

  const headshotPhotoUrl = headshotPhoto && headshotPhoto.attributes.variants['default']?.url;
  const featuredPhotoUrl =
    featuredWorkPhoto && featuredWorkPhoto.attributes.variants['default']?.url;

  const mainImage = featuredPhotoUrl || l.images[0].attributes.variants['default']?.url;
  const hoverImage = headshotPhotoUrl || hoverPic;

  const firstName = publicData.firstName;
  const lastInitial = publicData.lastInitial;
  const customTitle = `${firstName} ${lastInitial}.`;
  return (
    <NamedLink className={css.simpleListingCardWrapper} name="ListingPage" params={{ id, slug }}>
      <img
        className={imageClassName || css.listingBackgroundImage}
        src={mainImage}
        onMouseOver={e => (e.currentTarget.src = hoverImage)}
        onMouseOut={e => (e.currentTarget.src = mainImage)}
      />
      <div className={css.infoWrapper}>
        <div className={css.infoWrapperTop}>
          <div className={css.textBoxTitle}>{customTitle}</div>

          {/* <div className={css.saveButtonWrapper}>
            {currentUser && (
              <img
                onClick={handleSave}
                src={isFavourite ? bookmarked : notBookmarked}
                className={css.favIcon}
              />
            )}
          </div> */}
        </div>

        <div className={css.textBoxBody}>{composeDescription(currentListing)}</div>
      </div>
    </NamedLink>
  );
};

ListingCardSimpleComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardSimpleComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardSimpleComponent);
