export const limitText = (text, limit) => {
  if (!text || !limit) {
    return null;
  }
  if (text.length > limit) {
    const newText = text.substring(0, limit) + '...';
    return newText;
  }

  return text;
};
