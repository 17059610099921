import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import css from './TopbarDesktop.module.css';
import { createSlug } from '../../util/urlHelpers';
import HeaderNavigation from './components/HeaderNavigation/HeaderNavigation';
import { ClassNames } from '@emotion/react';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    transparent,
    currentUserListing,
  } = props;
  let isAdmin = false;
  if (typeof window !== 'undefined' && window.sessionStorage) {
    isAdmin = window?.sessionStorage.getItem('isAdmin');
  }
  const [mounted, setMounted] = useState(false);
  const [showHeaderNavigation, setShowHeaderNavigation] = useState(false);
  const [mouseEntered, setMouseEntered] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = transparent
    ? classNames(rootClassName || css.rootTransparent, className)
    : classNames(rootClassName || css.root, className);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? 'currentPage' : null;
  };
  const currentUserListingIsDraft =
    currentUserHasListings && currentUserListing?.attributes?.state === 'draft';
  const isTalent = currentUser?.attributes?.profile?.protectedData?.profileType === 'talent';

  const currentUserListingTitle = currentUserListing && currentUserListing?.attributes?.title;
  const slug = createSlug(currentUserListingTitle || 'listing');

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel
        className={transparent ? css.profileMenuLabelTransparent : css.profileMenuLabelcss}
        isOpenClassName={'profileMenuIsOpen'}
      >
        <Avatar
          className={css.avatar}
          user={currentUser}
          useWhiteIcon={transparent}
          disableProfileLink
        />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {isTalent && isAdmin ? (
          currentUserHasListings ? (
            <MenuItem key="NewListingPage">
              <NamedLink
                className={classNames(
                  css.yourListingsLink,
                  css.iconMenuLink,
                  currentPageClass('NewListingPage')
                )}
                name="EditListingPage"
                params={{
                  id: currentUserListing?.id?.uuid,
                  slug,
                  type: currentUserListingIsDraft ? 'draft' : 'edit',
                  tab: 'description',
                }}
              >
                <span className={css.menuItemBorder} />
                <span className={css.iconMenuLink}>Your profile</span>
              </NamedLink>
            </MenuItem>
          ) : (
            <MenuItem key="NewListingPage">
              <NamedLink
                className={classNames(
                  css.yourListingsLink,
                  css.iconMenuLink,
                  currentPageClass('NewListingPage')
                )}
                name="NewListingPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage className={css.iconMenuLink} id="TopbarDesktop.newListingPage" />
              </NamedLink>
            </MenuItem>
          )
        ) : (
          <MenuItem key="empty-item"></MenuItem>
        )}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(
              css.yourListingsLink,
              currentPageClass('AccountSettingsPage'),
              css.iconMenuLink
            )}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton
            rootClassName={classNames(css.logoutButton)}
            className={css.iconMenuLink}
            onClick={onLogout}
          >
            <span className={'menuItemBorder'} />
            <strong className={css.iconMenuLink}>Log Out</strong>
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      className={transparent ? css.signupLinkTransparent : css.signupLink}
    >
      <span className="signup">
        <FormattedMessage id="TopbarDesktop.signup2" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={transparent ? css.loginLinkTransparent : css.loginLink}>
      <span className={'login'}>
        <FormattedMessage id="TopbarDesktop.login2" />
      </span>
    </NamedLink>
  );

  const currentUserFirstName = currentUser?.attributes?.profile?.firstName;
  return (
    <nav className={classes}>
      {!transparent && (
        <div className={classNames(css.sectionTop, css.sectionBase)}>
          <div className={classNames(css.sectionLeft, css.sectionBase)}>
            <div
              className={css.linkWrapper}
              onMouseEnter={() => {
                setShowHeaderNavigation(true);
              }}
              onMouseLeave={() => {
                setShowHeaderNavigation(false);
              }}
            >
              <NamedLink className={css.sectionLeftLink} name={'SearchPage'}>
                Book talent
              </NamedLink>
            </div>

            <NamedLink className={css.sectionLeftLink} name={'AboutPage'}>
              About
            </NamedLink>
            {/* <NamedLink className={css.sectionLeftLink} name={'BlogHomePage'}>
              Blog
            </NamedLink> */}
          </div>

          <div className={classNames(css.sectionCenter, css.sectionBase)}>
            {' '}
            <NamedLink
              className={transparent ? css.logoLinkTransparent : css.logoLink}
              name="LandingPage"
            >
              <Logo
                format="desktop"
                className={css.logo}
                alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                transparent={transparent}
              />
            </NamedLink>{' '}
          </div>

          <div className={classNames(css.sectionRight, css.sectionBase)}>
            {isAuthenticatedOrJustHydrated ? (
              <>
                {currentUserFirstName && (
                  <div className={css.sectionRightItem}>
                    <span name={'LandingPage'} className={css.sectionRightSpan}>
                      {`Welcome, ${currentUserFirstName}`}
                    </span>
                  </div>
                )}
                <NamedLink
                  className={classNames(css.sectionRightItem, css.sectionRightSpan)}
                  name={'FAQPage'}
                >
                  FAQ
                </NamedLink>
                <div className={css.sectionRightItemForProfile}>{profileMenu}</div>
              </>
            ) : (
              <>
                <div className={css.sectionRightItem}>{loginLink}</div>
                {currentUserFirstName && (
                  <div className={css.sectionRightItem}>
                    <span name={'LandingPage'} className={css.sectionRightSpan}>
                      {`Welcome, ${currentUserFirstName}`}
                    </span>
                  </div>
                )}
                <div className={css.sectionRightItem}>{signupLink}</div>
              </>
            )}
          </div>
        </div>
      )}

      {transparent && (
        <div className={classNames(css.transparentLogoSection)}>
          <NamedLink
            className={transparent ? css.logoLinkTransparent : css.logoLink}
            name="LandingPage"
          >
            <Logo
              format="desktop"
              className={css.logoLarge}
              alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
              transparent={transparent}
            />
          </NamedLink>
        </div>
      )}

      {transparent && (
        <div className={classNames(css.sectionBottom, css.sectionBase)}>
          <div className={css.sectionBottomLeft}>
            <NamedLink className={css.sectionBottomLeftLink} name={'SearchPage'}>
              Book talent
            </NamedLink>
            <NamedLink className={css.sectionBottomLeftLink} name={'AboutPage'}>
              About
            </NamedLink>
            {/* <NamedLink className={css.sectionBottomLeftLink} name={'BlogHomePage'}>
              Blog
            </NamedLink> */}
          </div>
          <div className={css.sectionBottomRight}>
            {isAuthenticatedOrJustHydrated ? (
              <>
                {currentUserFirstName && (
                  <div className={css.sectionBottomRightItem}>
                    <span name={'LandingPage'}>{`Welcome, ${currentUserFirstName}`}</span>
                  </div>
                )}
                <div className={css.sectionBottomRightItemForProfile}>{profileMenu}</div>
              </>
            ) : (
              <>
                <div className={css.sectionBottomRightItem}>{loginLink}</div>
                {currentUserFirstName && (
                  <div className={css.sectionBottomRightItem}>
                    <span name={'LandingPage'}>{`Welcome, ${currentUserFirstName}`}</span>
                  </div>
                )}
                <div className={css.sectionBottomRightItem}>{signupLink}</div>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
