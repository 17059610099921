import config from '../../config';
import { findConfigForSelectFilter } from '../../util/search';

const determineArticle = str => {
  if (!str) {
    return null;
  }
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  return vowels.includes(str[0].toLowerCase()) ? 'an' : 'a';
};

export const composeDescription = listing => {
  const publicData = listing?.attributes?.publicData || {};
  const categoryConfig = findConfigForSelectFilter('category', config.custom.filters);
  const categories = categoryConfig.options ? categoryConfig.options : [];
  const label_firstName = publicData.firstName;
  const label_lastInitial = publicData.lastInitial;
  const label_category = categories.find(c => c?.key === publicData.category)?.label;
  const label_article = determineArticle(label_category);
  const label_city = publicData?.location?.address;
  const label_virtually = publicData.virtualServices === 'yes';

  if (!label_firstName || !label_lastInitial || !label_article || !label_category || !label_city) {
    return null;
  }
  const description = (
    <span>
      <span>{label_firstName + ' ' + label_lastInitial + ' is ' + label_article + ' '}</span>
      <strong>{label_category}</strong>
      <span>{' for hire in ' + label_city + (label_virtually ? ' and virtually' : '')}</span>
    </span>
  );

  return description;
};
