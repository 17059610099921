export function getTimeString(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${meridiem}`;
}

export const getFileNamesFromFilesMessage = message => {
  const filesString = message.replace('files#:', '');
  const filesArray = filesString.split(',');
  return filesArray;
};
