import React, { useState } from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import css from './RequestCompletePaymentModal.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { post } from '../../../util/api';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const RequestCompletePaymentModal = props => {
  const {
    currentTransaction,
    onManageDisableScrolling,
    isProvider,
    onRequestCompletePayment,
    showRequestCompletePaymentButton,
    isCompletePaymentRequested,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const destinationUserId = currentTransaction?.provider?.id?.uuid;
  const protectedData = currentTransaction?.attributes?.protectedData;
  const completePaymentObject = protectedData?.completePayment;
  const selectedService = currentTransaction?.attributes?.protectedData?.focusedService;

  console.log(selectedService);
  const currentLineItems = currentTransaction?.attributes?.lineItems || [];
  const depositLineItem = currentLineItems.find(l => l?.code && l.code.includes('deposit'));
  const depositTotal = depositLineItem && depositLineItem?.lineTotal?.amount;

  const amountLeftToPay =
    (depositTotal *
      (Number(100 - Number(selectedService?.depositPercentage)) /
        Number(selectedService?.depositPercentage))) /
    100;
  // Number(selectedService?.price) * (1 - Number(selectedService?.depositPercentage) / 100);

  const submit = () => {
    const data = {
      completePayment: {
        amount: amountLeftToPay,
      },
    };
    setLoading(true);
    onRequestCompletePayment(currentTransaction.id.uuid, data)
      .then(resp => {
        setModalOpen(false);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const errorMessage = 'Woops! Something went wrong. Please try again.';
  const successMessage = 'Congratulations! Your tip was send successfully!';
  const submitDisabled = !submitReady;

  return (
    <div className={css.tipsSectionWrapper}>
      {showRequestCompletePaymentButton && (
        <Button className={css.sendTipButton} type="button" onClick={() => setModalOpen(true)}>
          Request complete payment
        </Button>
      )}

      {completePaymentObject && isCompletePaymentRequested && isProvider && (
        <p className={css.pendingRequest}>
          Waiting for the customer to confirm the payment for the remaining {`$${amountLeftToPay}`}
        </p>
      )}

      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div>
          <center>
            <h2>Request the payment for the remaining ${amountLeftToPay}</h2>
          </center>

          <Button className={css.submitTipButton} disabled={false} type="button" onClick={submit}>
            {submitted ? (
              <CheckIcon />
            ) : loading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress className={css.spinnerMiddle} />
              </Box>
            ) : (
              'Send request'
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RequestCompletePaymentModal;
