import designIcon from '../../assets/icons/designIcon.png';
import bookIcon from '../../assets/icons/bookIcon.png';
import inquireIcon from '../../assets/icons/inquireIcon.png';
import invoiceIcon from '../../assets/icons/invoiceIcon.png';
import meetIcon from '../../assets/icons/meetIcon.png';
import quoteIcon from '../../assets/icons/quoteIcon.png';

export const getWhatToExpectData = focusedService => {
  if (!focusedService) {
    return [];
  }

  const isConsultativeService = focusedService.typeOfService === 'consultativeService';
  const isExperimentalService = focusedService.typeOfService === 'experimental';

  let whatToExpectData = [
    {
      title: 'INQUIRE',
      image: inquireIcon,
      description: 'Submit a request for a custom piece made by one of our talented Friends.',
    },
    {
      title: 'BOOK',
      image: bookIcon,
      description:
        "If your request is approved, we'll connect you via email for any back and forth about the design before booking is confirmed.",
    },
    {
      title: 'PAY',
      image: invoiceIcon,
      description: "You'll receive a link to submit payment when pricing is confirmed.",
    },
    {
      title: 'COMMISSION',
      image: designIcon,
      description: 'Talent will get to work on your custom piece!',
    },
  ];

  if (isConsultativeService) {
    whatToExpectData = [
      {
        title: 'INQUIRE',
        image: inquireIcon,
        description: 'Submit a request to meet one-on-one with one of our talented Friends.',
      },
      {
        title: 'BOOK',
        image: bookIcon,
        description:
          "If your request is approved, we'll introduce you via email for any back and forth before booking is confirmed.",
      },
      {
        title: 'MEET',
        image: meetIcon,
        description:
          "We'll send a calendar invite for the agreed upon meeting time so you can get connected!",
      },
      {
        title: 'PAY',
        image: invoiceIcon,
        description: "We'll send you a link to submit payment for services rendered.",
      },
    ];
  }

  if (isExperimentalService) {
    whatToExpectData = [
      {
        title: 'INQUIRE',
        image: inquireIcon,
        description: 'Submit a request to work with one of our talented Friends.',
      },
      {
        title: 'QUOTE',
        image: quoteIcon,
        description:
          "If your request is approved, we'll connect you via email to discuss the scope of work and quote.",
      },
      {
        title: 'BOOK',
        image: bookIcon,
        description:
          'After you approve the proposed quote and confirm details, your service will be booked!',
      },
      {
        title: 'PAY',
        image: invoiceIcon,
        description: "We'll send you a link to submit payment for services rendered.",
      },
    ];
  }

  return whatToExpectData;
};
