import React, { useState, useEffect } from 'react';
import css from './GiftcardField.module.css';
import { Field } from 'react-final-form';
import { get, post } from '../../util/api';

const GiftcardField = props => {
  const [giftcardsCodes, setGiftcardsCodes] = useState([]);
  useEffect(() => {
    get('/api/show-giftcards')
      .then(resp => {
        // const notUsedGiftcards = console.log(resp);
      })
      .catch(e => {
        //igore error
      });
  }, []);

  const giftcardField = ({ input: { onChange, value }, label, ...rest }) => (
    <div className={css.wrapper}>
      <h2 className={css.label}>
        {/* {'Gift Card or Promo Code'}
        <br />
        {'Apply Code (optional)'} */}
      </h2>
    </div>
  );

  return <Field name="giftcard" component={giftcardField} />;
};

export default GiftcardField;
