import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import css from './ImageUploader.module.css';
import { Field } from 'react-final-form';
import Button from '../Button/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { useDebounceEffect } from '../../forms/EditListingPhotosForm/useDebounceEffect';
import { canvasPreview } from '../../forms/EditListingPhotosForm/canvasPreview';
import { filterImagesValues, imagesConfig } from '../../forms/EditListingPhotosForm/utils';
import Modal from '../Modal/Modal';

const ImageUploader = ({ name, id }) => {
  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('react-image-crop/dist/ReactCrop.css');
    }
  });

  const uploadField = ({ input: { onChange, value }, label, ...rest }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    // -----------------------------  crop START -----------------------------

    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({
      unit: 'px', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 120,
      height: 120,
      aspect: 1,
    });
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(1);
    const [blobFile, setBlobFile] = useState(null);
    const [cropModalOpen, setCropModalOpen] = useState(false);
    const [loadingCrop, setLoadingCrop] = useState(false);

    const [originalSizeImage, setOriginalSizeImage] = useState(null);

    // -----------------------------  crop END -----------------------------

    const onFileChange = e => {
      setSelectedFile(e.target.files[0]);

      const file = e.target.files[0];
      setOriginalSizeImage(file);
      // form.change(`addImage`, file);
      // form.blur(`addImage`);
      // onImageUploadHandler(file);

      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
      reader.readAsDataURL(file);

      setCropModalOpen(true);

      {
        /* -----------------------------  crop START  ----------------------------- */
      }
      // return compress
      //   .compress([e.target.files[0]], {
      //     size: 1, // the max size in MB, defaults to 2MB
      //     quality: 0.5, // the quality of the image, max is 1,
      //     maxWidth: 1920, // the max width of the output image, defaults to 1920px
      //     maxHeight: 1920, // the max height of the output image, defaults to 1920px
      //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
      //     rotate: false, // See the rotation section below
      //   })
      //   .then(data => {
      //     const base64str = data[0].data;
      //     const imgExt = data[0].ext;
      //     const file = Compress.convertBase64ToFile(base64str, imgExt);
      //     setCrop(undefined); // Makes crop preview update between images.
      //     const reader = new FileReader();
      //     reader.addEventListener('load', () =>
      //       setImgSrc(reader.result.toString() || '')
      //     );
      //     reader.readAsDataURL(file);

      //     setCropModalOpen(true);
      //   });

      {
        /* -----------------------------  crop END ----------------------------- */
      }
    };

    const onFileUpload = (file, closeModal) => {
      const formData = new FormData();

      formData.append('image', file, file.name);
      return axios
        .post(`${isDev ? 'http://localhost:3500' : ''}/api/azure-upload`, formData)
        .then(resp => {
          console.log(resp);
          const image = resp?.data?.file;
          setImageUrl(image.url);
          onChange(image);
          closeModal();
        })
        .catch(error => {
          console.log(error);
        });
    };

    {
      /* -----------------------------  crop START ----------------------------- */
    }

    function onImageLoad(e) {
      if (aspect) {
        const { width, height } = e.currentTarget;
        setCrop({
          unit: 'px', // Can be 'px' or '%'
          x: 25,
          y: 25,
          width: 120,
          height: 120,
          aspect: 1,
        });

        setTimeout(() => {
          if (previewCanvasRef.current) {
            var canvas = document.getElementById('previewCanvas');

            canvas.toBlob(function(blob) {
              setBlobFile(blob);
            });
          }
        }, 1000);
      }
    }

    useDebounceEffect(
      async () => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
        }
      },
      100,
      [completedCrop, scale, rotate]
    );

    const handleCompletedCrop = c => {
      setCompletedCrop(c);

      setTimeout(() => {
        if (previewCanvasRef.current) {
          var canvas = document.getElementById('previewCanvas');

          canvas.toBlob(function(blob) {
            setBlobFile(blob);
            setLoadingCrop(false);
          });
        }
      }, 1000);
    };

    const onSave = () => {
      const newImage = new File([blobFile], selectedFile?.name, {
        type: blobFile.type,
      });
      // const originalImage = new File([originalSizeImage], key + '-origin', {
      //   type: blobFile.type,
      // });

      onFileUpload(newImage, () => setCropModalOpen(false));
      // const allImages = [
      //   {
      //     key,
      //     file: newImage,
      //   },
      //   {
      //     key: key + '-origin',
      //     file: originalImage,
      //   },
      // ];

      // allImages.forEach(img => {
      //   form.change(img.key, img.file);
      //   form.blur(img.key);
      //   onImageUploadHandler(img.file);
      // });

      // setCropModalOpen(false);
    };

    {
      /* -----------------------------  crop END ----------------------------- */
    }

    if (imageUrl || value?.url) {
      return (
        <div className={css.imageWrapper}>
          <DeleteIcon className={css.deleteButton} onClick={() => onChange(null)} />
          <img src={imageUrl || value?.url} className={css.uploadedImage} />
        </div>
      );
    }

    return (
      <div className={css.wrapper}>
        <input type="file" accept="image/*" onChange={onFileChange} />
        {/* <Button
          className={css.uploadButton}
          type="button"
          onClick={onFileUpload}
          disabled={!selectedFile}
        >
          Upload
        </Button> */}

        {/* -----------------------------  crop START ----------------------------- */}

        <Modal
          isOpen={cropModalOpen}
          onClose={() => {
            setCropModalOpen(false);
          }}
          onManageDisableScrolling={() => {}}
        >
          <div className={css.modalContentWrapper}>
            <div className={css.modalContentTopSection}>
              <div className={css.croppingToolWrapper}>
                <h2>Edit</h2>

                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => {
                    setLoadingCrop(true);
                    setCrop(percentCrop);
                  }}
                  onComplete={handleCompletedCrop}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>

              <div className={css.previewWrapper}>
                <h2 className={css.previewCanvas}>Preview</h2>

                {completedCrop && (
                  <canvas
                    id="previewCanvas"
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: '100%', //completedCrop.width,
                      height: '100%', //completedCrop.height,
                    }}
                    className={css.previewCanvas}
                  />
                )}
              </div>
            </div>

            <div
              className={blobFile && !loadingCrop ? css.saveButton : css.saveButtonDisabled}
              onClick={blobFile && !loadingCrop ? onSave : () => {}}
            >
              Add
            </div>
          </div>
        </Modal>

        {/* -----------------------------  crop END ----------------------------- */}
      </div>
    );
  };

  return <Field id={id} name={name} component={uploadField} />;
};

export default ImageUploader;
