import React, { useState, useEffect } from 'react';
import arrowLeft from '../../assets/icons/arrowLeft.png';

import css from './ServiceDetailsPage.module.css';
import { getWhatToExpectData } from './utils';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { propTypes } from '../../util/types';
import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Modal,
  NamedLink,
  Page,
  LayoutWrapperFooter,
  Footer,
  CancellationsAndFeesSection,
} from '../../components';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { onSendConciergeQuestion, requestToBook } from './ServiceDetailsPage.duck';
import MessageForm from '../../forms/EnquiryForm/MessageForm';
import { userDisplayNameAsString } from '../../util/data';
import { sendEnquiryError } from '../ListingPage/ListingPage.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import classNames from 'classnames';

const { UUID } = sdkTypes;

const ServiceDetailsComponent = props => {
  const {
    getListing,
    getService,
    setEnquiryModalOpen,
    setIsMessage,
    onRequestToBook,
    params: rawParams,
    isAuthenticated,
    onManageDisableScrolling,
  } = props;
  const { id, slug, name: serviceName } = rawParams;
  const listingId = new UUID(id);
  const currentListing = getListing(listingId);
  if (!currentListing) {
    return null;
  }

  const [isMessageFormOpen, setIsMessageFormOpen] = useState(false);
  const [isMessageSuccess, setIsMessageSuccess] = useState(false);
  const [sendEnquiryError, setSendEnquiryError] = useState(false);

  const currentService = currentListing.attributes.publicData.services.find(
    service => service.name === serviceName
  );

  if (!currentService) {
    const history = useHistory();
    history.push(`/l/${slug}/${id}`);
    return null;
  }

  const isCustomGoodSerive = currentService.typeOfService === 'customProduct';

  const isVariablePrice = currentService?.pricingType === 'variable';
  const priceAmount = isVariablePrice ? currentService.variablePriceAmount : currentService.price;
  const priceLabel = `$${priceAmount}`;
  const firstName = currentListing?.attributes?.publicData?.firstName;
  const lastInitial = currentListing?.attributes?.publicData?.lastInitial;
  const authorDisplayName = userDisplayNameAsString(currentListing.author, '');
  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData,
  } = currentListing.attributes;
  const fullName = firstName + ' ' + lastInitial;
  const firstImage =
    currentListing?.images && currentListing.images[0].attributes.variants['default']?.url;
  const bookingLink = currentListing?.attributes?.publicData?.bookingLink;

  const theFinePrintLabel = Array.isArray(currentService?.theFinePrint) && (
    <>
      {Array.isArray(currentService.theFinePrint) &&
        currentService.theFinePrint.map((i, index) => (
          <p className={classNames(css.listItem, css.detailsRowText)} key={index}>
            • {i?.text}
          </p>
        ))}
    </>
  );

  const whatsIncluded = currentService.whatsIncluded;
  const whatsIncludedLabel = Array.isArray(currentService?.whatsIncluded) && (
    <>
      {Array.isArray(currentService.whatsIncluded) &&
        currentService.whatsIncluded.map((i, index) => (
          <p className={classNames(css.listItem, css.detailsRowText)} key={index}>
            • {i?.text}
          </p>
        ))}
    </>
  ); //shortenText(whatsIncluded, 100);

  const perfectFor = currentService.perfectFor;
  const perfectForLabel = Array.isArray(currentService?.perfectFor) && (
    <>
      {Array.isArray(currentService.perfectFor) &&
        currentService.perfectFor.map((i, index) => (
          <p className={classNames(css.listItem, css.detailsRowText)} key={index}>
            • {i?.text}
          </p>
        ))}
    </>
  ); //shortenText(perfectFor, 100);
  const includeStartingAt = currentService?.includeStartingAt === 'true';
  const shippingAndDeliveryLabel = Array.isArray(currentService?.shippingAndDelivery) && (
    <>
      {Array.isArray(currentService.shippingAndDelivery) &&
        currentService.shippingAndDelivery?.filter(i => i)?.length > 0 &&
        currentService.shippingAndDelivery.map((i, index) => (
          <p className={classNames(css.listItem, css.detailsRowText)} key={index}>
            • {i?.text}
          </p>
        ))}
    </>
  );

  const bookButtonLabel = 'REQUEST TO BOOK';

  const whatToExpectData = getWhatToExpectData(currentService);
  const schemaTitle = currentService?.name;
  const schemaDescription = `${currentService?.name} details`;

  const onSubmitMessageForm = values => {
    const { params, onSendConciergeQuestion, currentUser, getListing } = props;
    const listingId = new UUID(params.id);
    const currentListing = getListing(listingId);
    const { message } = values;
    const trimmedMessage = message?.trim();
    onSendConciergeQuestion(currentService, currentListing, currentUser, trimmedMessage)
      .then(() => {
        setIsMessageSuccess(true);
        setIsMessageFormOpen(true);
        setSendEnquiryError(false);
      })
      .catch(err => {
        setIsMessageSuccess(false);
        setIsMessageFormOpen(true);
        setSendEnquiryError(true);
      });
  };

  return (
    <Page
      title={schemaTitle}
      contentType="website"
      description={schemaDescription}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.mainWrapper}>
          <div className={css.wrapper}>
            <div className={css.goBackSection}>
              <NamedLink name="ListingPage" params={{ id, slug }}>
                <img src={arrowLeft} className={css.backIcon} />
                <span className={css.backLabel}>BACK</span>
              </NamedLink>
            </div>

            <div className={css.nameSection}>
              {currentService?.name} • {fullName}
            </div>

            <div className={css.aboutSection}>
              <div className={css.aboutLeft}>
                <div className={css.detailsRow}>
                  <span className={css.detailsRowTitle}>AT A GLANCE</span>
                  <p className={css.detailsRowText}>{currentService.description}</p>
                </div>
                <div className={css.detailsRow}>
                  <span className={css.detailsRowTitle}>THIS IS PERFECT FOR</span>
                  <span className={css.detailsRowText}>{perfectForLabel}</span>
                </div>
                <div className={classNames(css.detailsRow, css.mobileDetails)}>
                  <span className={css.detailsRowTitle}>INCLUDES</span>
                  <span className={css.detailsRowText}>{whatsIncludedLabel}</span>
                </div>

                <div className={classNames(css.detailsRow, css.mobileDetails)}>
                  <span className={css.detailsRowTitle}>THE FINE PRINT</span>
                  <span className={css.detailsRowText}>{theFinePrintLabel}</span>
                </div>

                {shippingAndDeliveryLabel && isCustomGoodSerive && (
                  <div className={classNames(css.detailsRow, css.mobileDetails)}>
                    <span className={css.detailsRowTitle}>SHIPPING AND DELIVERY</span>
                    <span className={css.detailsRowText}>{shippingAndDeliveryLabel}</span>
                  </div>
                )}
              </div>

              <div className={css.imageWrapper}>
                <img
                  src={currentService.image.url ?? firstImage}
                  className={css.descriptionImage}
                />
              </div>
            </div>
            <div className={css.divider}></div>
            <div className={css.infoSection}>
              <div className={css.infoSectionLeft}>
                <div className={css.bookBox}>
                  <span className={css.bookBoxInfo}>{currentService.name}</span>
                  <span className={css.bookBoxInfo}>
                    {'PRICE: '}
                    {includeStartingAt && 'Starting at '} {priceLabel}
                  </span>
                  <div className={css.bookBoxButtons}>
                    <div
                      className={css.whiteButtonRight}
                      onClick={() => {
                        setIsMessageFormOpen(true);
                      }}
                    >
                      NEED HELP?
                    </div>
                    <a
                      className={css.whiteButtonLeft}
                      href={bookingLink}
                      onClick={() => onRequestToBook({ currentListing, currentService })}
                    >
                      <div>{bookButtonLabel}</div>
                    </a>
                  </div>
                </div>
              </div>

              <div className={classNames(css.infoSectionRight, css.details)}>
                <div className={classNames(css.detailsRow, css.desktopDetails)}>
                  <span className={css.detailsRowTitle}>INCLUDES</span>
                  <span className={css.detailsRowText}>{whatsIncludedLabel}</span>
                </div>

                <div className={classNames(css.detailsRow, css.desktopDetails)}>
                  <span className={css.detailsRowTitle}>THE FINE PRINT</span>
                  <span className={css.detailsRowText}>{theFinePrintLabel}</span>
                </div>

                {shippingAndDeliveryLabel && isCustomGoodSerive && (
                  <div className={classNames(css.detailsRow, css.desktopDetails)}>
                    <span className={css.detailsRowTitle}>SHIPPING AND DELIVERY</span>
                    <span className={css.detailsRowText}>{shippingAndDeliveryLabel}</span>
                  </div>
                )}
              </div>
            </div>

            <div className={css.whatToExpectSection}>
              <center>
                <span className={css.whatToExpectSectionTitle}>WHAT TO EXPECT</span>
              </center>
              <div className={css.whatToExpectSectionColumns}>
                {whatToExpectData.map(i => {
                  return (
                    <div
                      className={
                        whatToExpectData?.length > 3
                          ? css.whatToExpectSectionColumnShort
                          : css.whatToExpectSectionColumnLong
                      }
                      key={i.title}
                    >
                      <img src={i?.image} className={css.whatToExpectSectionImage} />
                      <span className={css.whatToExpectSectionColumnTitle}>{i?.title}</span>
                      <span className={css.whatToExpectSectionColumnText}>{i?.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <CancellationsAndFeesSection></CancellationsAndFeesSection>
          </div>
          <Modal
            id="ListingPage.enquiry"
            contentClassName={css.enquiryModalContent}
            isOpen={isAuthenticated && isMessageFormOpen}
            onClose={() => {
              setIsMessageFormOpen(false);
              setIsMessageSuccess(false);
            }}
            wide={true}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <MessageForm
              className={css.enquiryForm}
              submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
              listingTitle={title}
              authorDisplayName={authorDisplayName}
              sendEnquiryError={sendEnquiryError}
              onSubmit={onSubmitMessageForm}
              onClose={() => {
                setIsMessageFormOpen(false);
                setIsMessageSuccess(false);
              }}
              inProgress={false}
              isMessageSuccess={isMessageSuccess}
              fullName={fullName}
              isSpecificServiceInquiry={true}
              focusedService={currentService}
            />
          </Modal>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ServiceDetailsComponent.defaultProps = {
  currentUser: null,
};

ServiceDetailsComponent.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string,
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getService: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { showListingError } = state;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    console.log(listings);
    return listings.length === 1 ? listings[0] : null;
  };

  const getService = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0].services[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    showListingError,
    getListing,
    getService,
  };
};

const mapDispatchToProps = dispatch => ({
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onSendConciergeQuestion: (currentService, currentListing, customerInfo, message) =>
    dispatch(onSendConciergeQuestion(currentService, currentListing, customerInfo, message)),
  onRequestToBook: ({ currentListing, currentService }) =>
    dispatch(requestToBook(currentListing, currentService)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendConciergeQuestion: (focusedService, currentListing, customerInfo, message) =>
    dispatch(onSendConciergeQuestion(focusedService, currentListing, customerInfo, message)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ServiceDetailsPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ServiceDetailsComponent);

export default ServiceDetailsPage;
