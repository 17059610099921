import React from 'react';
import NamedLink from '../../NamedLink/NamedLink';
import css from './DummyAccountSettingsTabs.module.css';

const DummyAccountSettingsTabs = props => {
  return (
    <div className={css.wrapper}>
      <NamedLink name="ContactDetailsPage" className={css.link}>
        Contact Details
      </NamedLink>
      <NamedLink name="PasswordChangePage" className={css.link}>
        Password
      </NamedLink>
      <span className={css.dummyLink}>Profile details</span>
    </div>
  );
};

export default DummyAccountSettingsTabs;
