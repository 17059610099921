import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, FieldSelect, FieldTextInput } from '../../components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from 'react-final-form';
import css from './EditListingFeaturesForm.module.css';
import { composeValidators, maxLength } from '../../util/validators';
import ImageUploader from '../../components/ImageUploader/ImageUploader';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        intl,
        form,
        form: {
          mutators: { push },
        },
        values,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('yogaStyles', filterConfig);

      const dynamicMaxLength = max =>
        maxLength(
          intl.formatMessage(
            { id: 'EditListingDetailsForm.maxLength' },
            {
              maxLength: max,
            }
          ),
          max
        );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <div className={css.dynamicFieldsLabel}>
            <FormattedMessage id="EditListingDetailsForm.servicesLabel" />
            <AddIcon
              className={css.addButton}
              onClick={() => {
                push('services', {
                  faqs: [undefined],
                  variationOptions: [undefined],
                  questions: [{ question: "Is there anything else you'd like to share?" }],
                  perfectFor: [undefined],
                  whatsIncluded: [undefined],
                  theFinePrint: [undefined],
                  shippingAndDelivery: [undefined],
                });
                if (typeof window !== 'undefined') {
                  window.scrollTo({
                    left: 0,
                    top: document.body.scrollHeight - 300,
                    behavior: 'smooth',
                  });
                }
              }}
            />
          </div>

          <div className={css.dynamicFieldsWrapper}>
            <FieldArray name="services">
              {({ fields }) =>
                fields.map((name, index) => {
                  const fieldsValue = fields.value;
                  const currentValue = fieldsValue[index];
                  const isConsultativeService =
                    currentValue?.typeOfService === 'consultativeService';
                  const isCustomProduct = currentValue?.typeOfService === 'customProduct';
                  const descriptionCurrentLength = currentValue?.description?.length || 0;
                  const currentSelectedQuestions = currentValue?.questions || [];
                  return (
                    <div className={css.dynamicFieldWrapper} key={name}>
                      <div className={css.dynamicFieldsSectionWrapper}>
                        <div className={css.closeIconWrapper}>
                          <CloseIcon
                            onClick={() => fields.remove(index)}
                            style={{ color: '#827D8D', cursor: 'pointer' }}
                          />
                        </div>

                        <label className={css.dynamicItemLabel}>Type of Service</label>
                        <FieldSelect
                          className={css.typeOfServiceField}
                          name={`${name}.typeOfService`}
                        >
                          <option value="" disabled hidden>
                            Pick something...
                          </option>
                          <option value="customProduct">Custom Product</option>
                          <option value="experimental">Experiential</option>
                          <option value="consultativeService">Consultative Service</option>
                        </FieldSelect>
                      </div>

                      <div className={css.dynamicFieldsSectionWrapper}>
                        <p className={css.dynamicFieldsSectionTitle}>Photo</p>
                        <ImageUploader id={`${name}.image`} name={`${name}.image`} />
                      </div>

                      {currentValue?.typeOfService && (
                        <div className={css.serviceSection}>
                          <div className={css.dynamicFieldsSectionWrapper}>
                            <p className={css.dynamicFieldsSectionTitle}>Service overview</p>

                            <label className={css.dynamicItemLabel}>
                              {isCustomProduct ? 'Name of product' : 'Name of Service'}
                            </label>
                            <Field
                              name={`${name}.name`}
                              className={css.dynamicField}
                              component="input"
                              placeholder={'TYPE HERE'}
                            />

                            <label className={css.dynamicItemLabel}>
                              {currentValue?.typeOfService === 'customProduct'
                                ? 'Description of Product'
                                : 'Description of Service'}
                              {` (${descriptionCurrentLength}/310)`}
                            </label>
                            <FieldTextInput
                              name={`${name}.description`}
                              className={css.dynamicFieldTextInput}
                              // component="input"
                              type="textarea"
                              placeholder={intl.formatMessage({
                                id: 'EditListingDetailsForm.serviceDescriptionPlaceholder',
                              })}
                              validate={composeValidators(dynamicMaxLength(310))}
                            />

                            {/* ############################################################### */}

                            <label className={css.dynamicItemLabel}>
                              {'What’s this perfect for'}
                              <AddIcon
                                className={css.addButton}
                                onClick={() => push(`${name}.perfectFor`, undefined)}
                              />
                            </label>
                            <FieldArray name={`${name}.perfectFor`}>
                              {({ fields }) => {
                                return fields.map((name, index) => (
                                  <div className={css.questionWrapper} key={name}>
                                    {index > 0 && (
                                      <div className={css.closeIconWrapper}>
                                        <CloseIcon
                                          onClick={() => {
                                            fields.remove(index);
                                          }}
                                          style={{ color: '#827D8D', cursor: 'pointer' }}
                                        />
                                      </div>
                                    )}

                                    <label className={css.dynamicItemLabel}>Item {index + 1}</label>
                                    <Field
                                      name={`${name}.text`}
                                      className={css.dynamicField}
                                      component="input"
                                      placeholder={intl.formatMessage({
                                        id: 'EditListingDetailsForm.perfectForPlaceholder',
                                      })}
                                    />
                                  </div>
                                ));
                              }}
                            </FieldArray>

                            {/* ################################################################## */}

                            {/* ############################################################### */}

                            <label className={css.dynamicItemLabel}>
                              {'What this includes'}
                              <AddIcon
                                className={css.addButton}
                                onClick={() => push(`${name}.whatsIncluded`, undefined)}
                              />
                            </label>
                            <FieldArray name={`${name}.whatsIncluded`}>
                              {({ fields }) => {
                                return fields.map((name, index) => (
                                  <div className={css.questionWrapper} key={name}>
                                    {index > 0 && (
                                      <div className={css.closeIconWrapper}>
                                        <CloseIcon
                                          onClick={() => {
                                            fields.remove(index);
                                          }}
                                          style={{ color: '#827D8D', cursor: 'pointer' }}
                                        />
                                      </div>
                                    )}
                                    <label className={css.dynamicItemLabel}>Item {index + 1}</label>
                                    <Field
                                      name={`${name}.text`}
                                      className={css.dynamicField}
                                      component="input"
                                      placeholder={intl.formatMessage({
                                        id: 'EditListingDetailsForm.whatsIncludedPlaceholder',
                                      })}
                                    />
                                  </div>
                                ));
                              }}
                            </FieldArray>

                            {/* ################################################################## */}

                            {/* ############################################################### */}

                            <label className={css.dynamicItemLabel}>
                              {'The fine print'}
                              <AddIcon
                                className={css.addButton}
                                onClick={() => push(`${name}.theFinePrint`, undefined)}
                              />
                            </label>
                            <FieldArray name={`${name}.theFinePrint`}>
                              {({ fields }) => {
                                return fields.map((name, index) => (
                                  <div className={css.questionWrapper} key={name}>
                                    {index > 0 && (
                                      <div className={css.closeIconWrapper}>
                                        <CloseIcon
                                          onClick={() => {
                                            fields.remove(index);
                                          }}
                                          style={{ color: '#827D8D', cursor: 'pointer' }}
                                        />
                                      </div>
                                    )}
                                    <label className={css.dynamicItemLabel}>Item {index + 1}</label>
                                    <Field
                                      name={`${name}.text`}
                                      className={css.dynamicField}
                                      component="input"
                                      placeholder={intl.formatMessage({
                                        id: 'EditListingDetailsForm.theFinePrintPlaceholder',
                                      })}
                                    />
                                  </div>
                                ));
                              }}
                            </FieldArray>

                            {/* ################################################################## */}

                            {isCustomProduct && (
                              <>
                                {/* ############################################################### */}

                                <label className={css.dynamicItemLabel}>
                                  {'Shipping and Delivery'}
                                  <AddIcon
                                    className={css.addButton}
                                    onClick={() => push(`${name}.shippingAndDelivery`, undefined)}
                                  />
                                </label>
                                <FieldArray name={`${name}.shippingAndDelivery`}>
                                  {({ fields }) => {
                                    return fields.map((name, index) => (
                                      <div className={css.questionWrapper} key={name}>
                                        {index > 0 && (
                                          <div className={css.closeIconWrapper}>
                                            <CloseIcon
                                              onClick={() => {
                                                fields.remove(index);
                                              }}
                                              style={{ color: '#827D8D', cursor: 'pointer' }}
                                            />
                                          </div>
                                        )}
                                        <label className={css.dynamicItemLabel}>
                                          Item {index + 1}
                                        </label>
                                        <Field
                                          name={`${name}.text`}
                                          className={css.dynamicField}
                                          component="input"
                                          placeholder={intl.formatMessage({
                                            id: 'EditListingDetailsForm.theFinePrintPlaceholder',
                                          })}
                                        />
                                      </div>
                                    ));
                                  }}
                                </FieldArray>

                                {/* ################################################################## */}
                              </>
                            )}

                            {isConsultativeService && (
                              <>
                                <label className={css.dynamicItemLabel}>
                                  {'Duration of Consultation (in minutes)'}
                                </label>
                                <Field
                                  name={`${name}.durationOfConsultation`}
                                  className={css.dynamicField}
                                  component="input"
                                  placeholder={'100 min'}
                                />
                              </>
                            )}
                          </div>

                          <div className={css.dynamicFieldsSectionWrapper}>
                            <p className={css.dynamicFieldsSectionTitle}>Pricing</p>
                            <div className={css.pricingRow}>
                              <p className={css.pricingRowLabel}>Pricing Type</p>

                              <FieldCheckboxGroup
                                className={css.checkboxGroup}
                                id={`${name}.pricingType`}
                                name={`${name}.pricingType`}
                                options={[
                                  {
                                    key: 'variable',
                                    label: (
                                      <span className={css.pricingTypeOptionLabel}>
                                        <span className={css.pricingTypeOptionLabelTop}>
                                          Variable
                                        </span>
                                        <span className={css.pricingTypeOptionLabelBottom}>
                                          Calculated by unit ie: per head, per hour
                                        </span>
                                      </span>
                                    ),
                                  },
                                  {
                                    key: 'static',
                                    label: (
                                      <span className={css.pricingTypeOptionLabel}>
                                        <span className={css.pricingTypeOptionLabelTop}>
                                          Static
                                        </span>
                                        <span className={css.pricingTypeOptionLabelBottom}>
                                          A concrete price
                                        </span>
                                      </span>
                                    ),
                                  },
                                ]}
                                radio={true}
                                twoColumns={true}
                              />
                            </div>

                            {currentValue?.pricingType === 'static' && (
                              <>
                                <div className={css.pricingRow}>
                                  <p className={css.pricingRowLabel}>Static Price</p>
                                  <Field
                                    name={`${name}.price`}
                                    className={css.dynamicFieldStaticPrice}
                                    component="input"
                                    type={'number'}
                                    placeholder={'PRICE'}
                                  />
                                </div>

                                {/* {isCustomProduct && ( */}
                                <FieldCheckboxGroup
                                  className={css.checkboxGroupStartingAt}
                                  id={`${name}.includeStartingAt`}
                                  name={`${name}.includeStartingAt`}
                                  options={[
                                    {
                                      key: 'true',
                                      label: (
                                        <span className={css.pricingTypeOptionLabel}>
                                          <span className={css.pricingTypeOptionLabelTop}>
                                            Include “Starting At”
                                          </span>
                                          <span className={css.pricingTypeOptionLabelBottom}>
                                            If the price could increase
                                          </span>
                                        </span>
                                      ),
                                    },
                                    {
                                      key: 'false',
                                      label: (
                                        <span className={css.pricingTypeOptionLabel}>
                                          <span className={css.pricingTypeOptionLabelTop}>
                                            Don’t include “Starting at”
                                          </span>
                                          <span className={css.pricingTypeOptionLabelBottom}>
                                            If the price won’t increase
                                          </span>
                                        </span>
                                      ),
                                    },
                                  ]}
                                  radio={true}
                                  twoColumns={true}
                                />
                                {/* )} */}
                              </>
                            )}

                            {currentValue?.pricingType === 'variable' && (
                              <>
                                <div className={css.pricingRow}>
                                  <p className={css.pricingRowLabel}>Variable Price</p>
                                  <Field
                                    name={`${name}.variablePriceAmount`}
                                    className={css.dynamicFieldStaticPrice}
                                    component="input"
                                    type={'number'}
                                    placeholder={'PRICE'}
                                  />
                                  <span className={css.pricingRowSeparator}>per</span>
                                  <Field
                                    name={`${name}.variablePriceUnit`}
                                    className={css.dynamicFieldStaticPrice}
                                    component="input"
                                    type={'text'}
                                    placeholder={'UNIT METRIC'}
                                  />
                                </div>
                                <FieldCheckboxGroup
                                  className={css.checkboxGroupStartingAt}
                                  id={`${name}.includeStartingAt`}
                                  name={`${name}.includeStartingAt`}
                                  options={[
                                    {
                                      key: 'true',
                                      label: (
                                        <span className={css.pricingTypeOptionLabel}>
                                          <span className={css.pricingTypeOptionLabelTop}>
                                            Include “Starting At”
                                          </span>
                                          <span className={css.pricingTypeOptionLabelBottom}>
                                            If the price could increase
                                          </span>
                                        </span>
                                      ),
                                    },
                                    {
                                      key: 'false',
                                      label: (
                                        <span className={css.pricingTypeOptionLabel}>
                                          <span className={css.pricingTypeOptionLabelTop}>
                                            Don’t include “Starting at”
                                          </span>
                                          <span className={css.pricingTypeOptionLabelBottom}>
                                            If the price won’t increase
                                          </span>
                                        </span>
                                      ),
                                    },
                                  ]}
                                  radio={true}
                                  twoColumns={true}
                                />
                              </>
                            )}

                            <div className={css.variationsWrapper}>
                              <p className={css.variationsLabel}>Variations</p>
                              <FieldCheckboxGroup
                                className={css.checkboxGroup}
                                id={`${name}.variation`}
                                name={`${name}.variation`}
                                options={[
                                  {
                                    key: 'offered',
                                    label: (
                                      <span className={css.variationOptionLabel}>
                                        Variations Offered
                                      </span>
                                    ),
                                  },
                                  {
                                    key: 'notOffered',
                                    label: (
                                      <span className={css.variationOptionLabel}>
                                        Variations Not offered
                                      </span>
                                    ),
                                  },
                                ]}
                                radio={true}
                                twoColumns={true}
                              />

                              {currentValue?.variation === 'offered' && (
                                <div className={css.variationOptionsWrapper}>
                                  <div className={css.variationOptionsTitleWrapper}>
                                    <p className={css.variationOptionsTitleLeft}>Variations</p>

                                    <p className={css.variationOptionsTitleRight}>Price</p>
                                  </div>

                                  <FieldArray name={`${name}.variationOptions`}>
                                    {({ fields }) => {
                                      return fields.map((name, index) => (
                                        <div className={css.variationOptionFieldWrapper} key={name}>
                                          <Field
                                            name={`${name}.label`}
                                            className={css.variationOptionField}
                                            component="input"
                                            type="text"
                                            placeholder={'VARIATION'}
                                          />

                                          <Field
                                            name={`${name}.price`}
                                            className={css.variationOptionField}
                                            component="input"
                                            type="number"
                                            placeholder={'PRICE'}
                                          />

                                          <div className={css.closeVariationIconWrapper}>
                                            <CloseIcon
                                              onClick={() => {
                                                if (index > 0) {
                                                  fields.remove(index);
                                                }
                                              }}
                                              style={{ color: '#827D8D', cursor: 'pointer' }}
                                            />
                                          </div>
                                        </div>
                                      ));
                                    }}
                                  </FieldArray>

                                  <div className={css.addVariationWrapper}>
                                    <p
                                      className={css.addVariationButton}
                                      onClick={() => push(`${name}.variationOptions`, undefined)}
                                    >
                                      + Add Variation
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className={css.dynamicFieldsSectionWrapper}>
                            <p className={css.dynamicFieldsSectionTitle}>Booking details</p>

                            <label className={css.dynamicItemLabel}>
                              Client Booking Questions
                              <AddIcon
                                className={css.addButton}
                                onClick={() =>
                                  form.change(`${name}.questions`, [
                                    { question: undefined },
                                    ...currentSelectedQuestions,
                                  ])
                                }
                              />
                            </label>
                            <FieldArray name={`${name}.questions`}>
                              {({ fields }) => {
                                return fields.map((name, index) => (
                                  <div className={css.questionWrapper} key={name}>
                                    <div className={css.closeIconWrapper}>
                                      <CloseIcon
                                        onClick={() => {
                                          if (index > 0) {
                                            fields.remove(index);
                                          }
                                        }}
                                        style={{ color: '#827D8D', cursor: 'pointer' }}
                                      />
                                    </div>
                                    <label className={css.dynamicItemLabel}>
                                      Question {index + 1}
                                    </label>
                                    <Field
                                      name={`${name}.question`}
                                      className={css.dynamicField}
                                      component="input"
                                      placeholder={intl.formatMessage({
                                        id: 'EditListingDetailsForm.whatToExpectPlaceholder',
                                      })}
                                    />
                                  </div>
                                ));
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              }
            </FieldArray>
          </div>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
