import React, { useState } from 'react';
import Button from '../../Button/Button';
import Modal from '../../Modal/Modal';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import css from './RequestExtraPaymentModal.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { post } from '../../../util/api';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const RequestExtraPaymentModal = props => {
  const {
    currentTransaction,
    onManageDisableScrolling,
    isProvider,
    onRequestSecondPayment,
    showRequestExtraPaymentButton,
    isSecondPaymentRequested,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [extraPaymentAmount, setExtraPaymentAmount] = useState(5);
  const [extraHoursCount, setExtraHoursCount] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [submitReady, setSubmitReady] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const destinationUserId = currentTransaction?.provider?.id?.uuid;
  const protectedData = currentTransaction?.attributes?.protectedData;
  const extraPaymentObject = protectedData?.extraPayment;

  const submit = () => {
    const data = {
      extraPayment: {
        hours: extraHoursCount,
        amount: extraPaymentAmount,
      },
    };
    setLoading(true);
    onRequestSecondPayment(currentTransaction.id.uuid, data)
      .then(resp => {
        setModalOpen(false);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const errorMessage = 'Woops! Something went wrong. Please try again.';
  const successMessage = 'Congratulations! Your tip was send successfully!';
  const submitDisabled = !submitReady;

  return (
    <div className={css.tipsSectionWrapper}>
      {showRequestExtraPaymentButton && (
        <Button className={css.sendTipButton} type="button" onClick={() => setModalOpen(true)}>
          Request extra payment
        </Button>
      )}

      {extraPaymentObject && isSecondPaymentRequested && isProvider && (
        <p className={css.pendingRequest}>
          Waiting for the customer to confirm the {`$${extraPaymentObject.amount}`} payment for the
          extra {extraPaymentObject.hours} {Number(extraPaymentObject.hours) > 1 ? 'hours' : 'hour'}
        </p>
      )}

      {/* {extraPaymentObject && (
        <div className={css.tipsItemsWrapper}>
          <p className={css.tipsLabel}>{'Extra payments'}</p>

          <div className={css.tipItemWrapper}>
            {`• ${extraPaymentObject.hours} * ${'pricePerHour'}-------${extraPaymentObject.amount}`}{' '}
            {!isProvider && (
              <a className={css.receipt} href={extraPaymentObject.receiptUrl} target="_blank">
                <ReceiptLongIcon />
              </a>
            )}
          </div>
        </div>
      )} */}

      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div>
          <center>
            <h2>Request extra payment</h2>
          </center>
          <div className={css.tipAmountWrapper}>
            <p>Number of extra hours:</p>
            <div className={css.amountControllerWrapper}>
              <ArrowLeftIcon
                onClick={() => {
                  if (extraPaymentAmount > 1) {
                    setExtraHoursCount(extraHoursCount - 1);
                  }
                }}
              />
              <span className={css.tipAmount}>{extraHoursCount}h</span>
              <ArrowRightIcon onClick={() => setExtraHoursCount(extraHoursCount + 1)} />
            </div>
          </div>
          <div className={css.tipAmountWrapper}>
            <p>Amount:</p>
            <div className={css.amountControllerWrapper}>
              <ArrowLeftIcon
                onClick={() => {
                  if (extraPaymentAmount > 5) {
                    setExtraPaymentAmount(extraPaymentAmount - 5);
                  }
                }}
              />
              <span className={css.tipAmount}>${extraPaymentAmount}</span>
              <ArrowRightIcon onClick={() => setExtraPaymentAmount(extraPaymentAmount + 5)} />
            </div>
          </div>

          <Button className={css.submitTipButton} disabled={false} type="button" onClick={submit}>
            {submitted ? (
              <CheckIcon />
            ) : loading ? (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress className={css.spinnerMiddle} />
              </Box>
            ) : (
              'Send'
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RequestExtraPaymentModal;
