import React, { useState, useEffect } from 'react';
import css from './ProfileTypeModal.module.css';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import { updateProfile } from '../../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { isScrollingDisabled } from '../../../ducks/UI.duck';

import { compose } from 'redux';
import { connect } from 'react-redux';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const ProfileTypeModalComponent = props => {
  const { currentUser, onUpdateProfile, isScrollingDisabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (
      currentUser?.attributes?.profile &&
      !currentUser?.attributes?.profile?.protectedData?.profileType
    ) {
      setIsOpen(true);
    }
  }, [currentUser]);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={() => {}} onManageDisableScrolling={() => {}}>
        <div className={css.contentWrapper}>
          <h2>Are you</h2>
          <div className={css.buttonsWrapper}>
            <Button
              onClick={() => {
                return onUpdateProfile({ protectedData: { profileType: 'talent' } })
                  .then(resp => {
                    setIsOpen(false);
                  })
                  .catch(e => {
                    //ignore error
                  });
              }}
              className={css.button}
            >
              Talent
            </Button>
            <h2>or</h2>
            <Button
              onClick={() => {
                return onUpdateProfile({ protectedData: { profileType: 'client' } })
                  .then(resp => {
                    setIsOpen(false);
                  })
                  .catch(e => {
                    //ignore error
                  });
              }}
              className={css.button}
            >
              Client
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  const { updateInProgress, updateProfileError } = state.ProfileSettingsPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const ProfileTypeModal = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProfileTypeModalComponent);

export default ProfileTypeModal;
