import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  getStartHours,
  getEndHours,
  isInRange,
  isSameDate,
  resetToStartOfDay,
  dateIsAfter,
  findNextBoundary,
  timestampToDate,
  localizeAndFormatTime,
} from '../../util/dates';
import './TimePicker.css';

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.addMinutes = function(m) {
  this.setTime(this.getTime() + (m*60*1000));
  return this;
}

export default function EndTime(props) {

  const { 
    intl, 
    timeZone, 
    bookingStart, 
    timeslots, 
    setEndTimeValue, 
    endTimeValue, 
    startTimeValue } = props;


  const handleChange = (event) => {
    setEndTimeValue(event.target.value);
  };


  const getTimeSlots = (timeSlots, date, timeZone) => {
    return timeSlots && timeSlots[0]
      ? timeSlots.filter(t => isInRange(date, t.attributes.start, t.attributes.end, 'day', timeZone))
      : [];
  };


  const getAvailableStartTimes = (intl, timeZone, bookingStart, timeSlotsOnSelectedDate) => {
    if (timeSlotsOnSelectedDate.length === 0 || !timeSlotsOnSelectedDate[0] || !bookingStart) {
      return [];
    }
    const bookingStartDate = resetToStartOfDay(bookingStart, timeZone);
  
    const allHours = timeSlotsOnSelectedDate.reduce((availableHours, t) => {
      const startDate = t.attributes.start;
      const endDate = t.attributes.end;
      const nextDate = resetToStartOfDay(bookingStartDate, timeZone, 1);
  
      // If the start date is after timeslot start, use the start date.
      // Otherwise use the timeslot start time.
      const startLimit = dateIsAfter(bookingStartDate, startDate) ? bookingStartDate : startDate;
  
      // If date next to selected start date is inside timeslot use the next date to get the hours of full day.
      // Otherwise use the end of the timeslot.
      const endLimit = dateIsAfter(endDate, nextDate) ? nextDate : endDate;
  
      const hours = getStartHours(intl, timeZone, startLimit, endLimit);
      return availableHours.concat(hours);
    }, []);
    return allHours;
  };


  const getAvailableEndTimes = (
    intl,
    timeZone,
    bookingStartTime,
    bookingEndDate,
    selectedTimeSlot
  ) => {
    if (!selectedTimeSlot || !selectedTimeSlot.attributes || !bookingEndDate || !bookingStartTime) {
      return [];
    }
  
    const endDate = selectedTimeSlot.attributes.end;
    const bookingStartTimeAsDate = timestampToDate(bookingStartTime);
  
    const dayAfterBookingEnd = resetToStartOfDay(bookingEndDate, timeZone, 1);
    const dayAfterBookingStart = resetToStartOfDay(bookingStartTimeAsDate, timeZone, 1);
    const startOfEndDay = resetToStartOfDay(bookingEndDate, timeZone);
  
    let startLimit;
    let endLimit;
  
    if (!dateIsAfter(startOfEndDay, bookingStartTimeAsDate)) {
      startLimit = bookingStartTimeAsDate;
      endLimit = dateIsAfter(dayAfterBookingStart, endDate) ? endDate : dayAfterBookingStart;
    } else {
      // If the end date is on the same day as the selected booking start time
      // use the start time as limit. Otherwise use the start of the selected end date.
      startLimit = dateIsAfter(bookingStartTimeAsDate, startOfEndDay)
        ? bookingStartTimeAsDate
        : startOfEndDay;
  
      // If the selected end date is on the same day as timeslot end, use the timeslot end.
      // Else use the start of the next day after selected date.
      endLimit = isSameDate(resetToStartOfDay(endDate, timeZone), startOfEndDay)
        ? endDate
        : dayAfterBookingEnd;
    }
  
    return getEndHours(intl, timeZone, startLimit, endLimit);
  };

  const selectedTimeSlot = timeslots.find(t =>
    isInRange(bookingStart, t.attributes.start, t.attributes.end)
    );

  

  const timeSlotsOnSelectedDate = getTimeSlots(
    timeslots,
    bookingStart,
    timeZone
  );

  const availableStartTimes = getAvailableStartTimes(
    intl,
    timeZone,
    bookingStart,
    timeSlotsOnSelectedDate
  );

  const availableEndTimes = [...availableStartTimes];

  const lastAvailableEndTime = availableEndTimes[availableEndTimes.length - 1];

  if(lastAvailableEndTime){

    const newLabel = localizeAndFormatTime(
      intl,
      timeZone,
      findNextBoundary(timeZone, lastAvailableEndTime.timestamp)
    );

    const lastTimeAvailable = {
      timestamp: lastAvailableEndTime.timestamp + 30*60*1000,
      timeOfDay: newLabel
    }

    // lastAvailableEndTime.timestamp = lastAvailableEndTime.timestamp + 30*60*1000;

    // lastAvailableEndTime.timeOfDay = newLabel
    availableEndTimes.push(lastTimeAvailable)
  }

  const filteredAvailableEndTimes = availableEndTimes.filter(i => {
    return i.timestamp > startTimeValue + 59*60*1000
  })


  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">End time</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={endTimeValue}
          label="End time"
          onChange={handleChange}
          disabled={!startTimeValue}
        >
          {filteredAvailableEndTimes.map(i => {

            return(
              <MenuItem value={i.timestamp}>{i.timeOfDay}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Box>
  );
}