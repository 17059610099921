const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const extractCity = string => {
  if (!string) {
    return null;
  }
  var accessToken = mapboxAccessToken;
  var baseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
  var query = encodeURIComponent(string);
  var url = baseUrl + query + '.json?access_token=' + accessToken;

  return fetch(url)
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      var city = data.features.find(function(feature) {
        return feature.place_type[0] === 'place';
      });
      if (city) {
        console.log(city.text);
        return city.text;
      } else {
        return string;
        console.log('City not found');
      }
    })
    .catch(function(error) {
      console.log('Error: ' + error);
      return string;
    });
};
